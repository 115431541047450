import React from 'react';
import { MemberApi } from 'apis';
import { useForm, useDispatch } from 'utils/hooks';
import { UIStore } from 'store';
import Modal, { ModalPropType } from '../templates/Modal';
import { Grid } from "@material-ui/core";
import { TextField } from 'components';
import styles from './style.module.scss';

export type LinkPatientToMemberProps = ModalPropType & {
  patientId: string,
  memberId?: string,
};

export default function LinkPatientToMember({ patientId, memberId, onClose, scrollTop, modalProps }: LinkPatientToMemberProps) {
  const dispatch = useDispatch();
  const { bind, form } = useForm({ memberId: memberId || '' });

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  }

  const onSubmit = async (values: any) => {
    try {
      await MemberApi.linkMember({
        patientId,
        memberId: values.memberId,
      });

      dispatch(UIStore.openAlert({ title: `Patient was linked to member ${values.memberId}` }));
      if (onClose) (onClose as Function)();
    } catch (error) {
      console.log(error)
      form.setFormError(`An error occured`);
      scrollTop();
    }
  }

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    }
  }
 
  return (
    <Modal
      {...modalProps}
      alert={form.formError}
      title="Link Patient to Member Account"
      description={<>Link a patient to a Zeel Account using the account's member ID.</>}
      className={styles.base}
      backdropClose={false}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Cancel', action: "close" },
        { label: 'Link Patient', action: "submit" },
      ]}
    > 
      <Grid className={styles.veteranFields} container spacing={3}>
        {/* <Grid item xs={12}><FieldTitle icon="orientation-card">New Zeel Account Information</FieldTitle></Grid> */}
        <Grid item xs={12}><TextField {...bind('memberId', { required: true })} label="Zeel Account Member ID" autoFocus {...fieldProps} /></Grid>
      </Grid>
    </Modal>
  );
}
