import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { v4 as uuidv4 } from 'uuid';

const version = 1;
const initialState = {
  menuOpened: false,
  menuExpanded: true,
  modals: [],
  alerts: [],
};

export const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    // navigation
    toggleMenu: (state) => {
      state.menuOpened = !state.menuOpened
    },
    toggleMenuExpansion: (state) => {
      state.menuExpanded = !state.menuExpanded
    },
    // modals
    openModal: (state, action) => {
      const { id = uuidv4(), component, props, callback } = action.payload;
      state.modals = state.modals.filter(modal => modal.id !== id);
      state.modals.push({
        id,
        props,
        callback,
        component,
      })
    },
    warnModalForClosure: (state, action) => {
      const { id } = action.payload;
      const modalIndex = state.modals.findIndex(m => m.id === id);
      if (modalIndex > -1) {
        state.modals[modalIndex].closing = true;
      }
    },
    closeModal: (state, action) => {
      const { id, result } = action.payload;
      const callback = state.modals.find(m => m.id === id)?.callback;
      state.modals = state.modals.filter(modal => modal.id !== id);
      if (callback) {
        setTimeout(() => {
          callback(result);
        })
      }
    },
    closeAllModals: (state) => {
      state.modals = [];
    },
    openAlert: (state, action) => {
      const { id = uuidv4(), title, severity = 'success', duration } = action.payload;
      state.alerts.push({ id, title, severity, duration });
    },
    closeAlert: (state, action) => {
      const { id } = action.payload;
      state.alerts = state.alerts.filter(alert => alert.id !== id);
    }
  },
});

const { name, actions, reducer } = slice;

// Actions
export const {
  toggleMenu,
  toggleMenuExpansion,
  openModal,
  closeAllModals,
  openAlert,
  closeAlert,
} = actions;

// Thunk
export const closeModal = ({ id, result }) => (
  async (dispatch) => {
    await dispatch(actions.warnModalForClosure({ id }));
    setTimeout(() => {
      dispatch(actions.closeModal({ id, result }));
    }, 300);
  }
)

// Selectors
export const isMenuOpened = state => {
  return state[name].menuOpened;
}
export const isMenuExpanded = state => {
  return state[name].menuExpanded;
}
export const getModals = state => {
  return state[name].modals;
}
export const getAlerts = state => {
  return state[name].alerts;
}

// Reducer
export default persistReducer({
  key: 'zeel:cms:ui',
  version,
  storage,
  blacklist: ['modals', 'alerts']
}, reducer);
