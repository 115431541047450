import React, { Component } from 'react';
import classnames from 'classnames';
import iconMapper from './map';
import styles from './style.module.scss';

interface Props {
  id?: string,
  name: string,
  title?: string,
  className?: string,
  style?: any,
  color?: string,
  size?: number, // will set the width and the height
  width?: number,
  height?: number,
  onClick?: Function,
};

export default class Icon extends Component<Props> {
  render() {
    const {
      id,
      name = '',
      title,
      className,
      style = {},
      color,
      size,
      width = size,
      height = size,
      onClick
    } = this.props;
    const colorMap: any = {
      'natural': '#f6f6f4',
      'lush': '#1e890a',
      'lush-light': '#d3e6d0',
      'teal': '#3b959f',
      'teal-light': '#faf9fa',
      'teal-light-alt': '#EBF4F5',
      'error': '#ef0000',
      'error-dark': '#d40b0b',
      'grey-1-alt': '#f7f8f8',
      'grey-1': '#f2f2f2',
      'grey-2': '#e2e2e2',
      'grey-3': '#c2c2c2',
      'grey-4': '#999999',
      'grey-5': '#646464',
      'grey-6': '#4a4a4a',
      'midnight': '#333',
      'orange': '#fd6b19',
      'yellow': '#e7b334',
      'beige': '#f5f0e9',
    }

    const IconComponent = (iconMapper as any)[name] || null;
    const iconProps: any = {
      id,
      color: color ? colorMap[color] || color : null,
      className: classnames(styles.icon, { [styles['icon--clickable']]: !!onClick }, className),
      style,
      onClick,
      title,
    };
    if (width) iconProps['width'] = `${width}px`;
    if (height) iconProps['height'] = `${height}px`;

    if (IconComponent) {
      return <IconComponent {...iconProps} />
    }
    return null;
  }
}
