import React from 'react';
import TextField, { TextFieldPropType } from '../TextField';
import { formatPhoneNumber } from 'utils/helper';
// import styles from './style.module.scss';

export type PhoneFieldPropType = TextFieldPropType;

export default function PhoneField(props: any) { // TODO: fix types
  return (
    <TextField {...props} value={formatPhoneNumber((props.value as string))} />
  );
}
