export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const matchFirst = cleaned.match(/^(1|)?(\d{3})$/);
  const matchSecond = cleaned.match(/^(1|)?(\d{3})(\d{3})$/);
  const matchFull = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  let formatted = phoneNumberString || '';

  if (matchFull) {
    const intlCode = (matchFull[1] ? '+1 ' : '');
    formatted = [intlCode, '(', matchFull[2], ') ', matchFull[3], '-', matchFull[4]].join('')
  }
  if (matchSecond) {
    const intlCode = (matchSecond[1] ? '+1 ' : '');
    formatted = [intlCode, '(', matchSecond[2], ') ', matchSecond[3], '-'].join('');
  }
  if (matchFirst) {
    const intlCode = (matchFirst[1] ? '+1 ' : '');
    formatted = [intlCode, '(', matchFirst[2], ') '].join('');
  }

  return formatted.substring(0, ((formatted || '')[0] === '+') ? 17 : 14);
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function scrollTop(id: string | null = null) {
  const element = (document as any).querySelector(id ? `#${id}` : 'body');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};