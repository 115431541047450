import React, { useState } from 'react';
import cn from 'classnames';
import { CircularProgress } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import styles from './style.module.scss';

export type ButtonPropType = ButtonProps;

export default function _Button(props: any) {
  const [loading, setLoading] = useState(false);
  const onClickHandler = async (e: any) => {
    setLoading(true);
    if (props.onClick && typeof props.onClick === 'function') {
      await props.onClick(e);
    }
    setLoading(false);
  }
  const isPrimary = props.state === 'primary';
  const isSecondary = props.state === 'secondary';
  const isLoading = loading || props.loading;
  return (
    <Button
      {...props}
      variant={props.variant || (isPrimary && 'contained') || (isSecondary && 'outlined')}
      color={props.color || (isPrimary && 'primary') || (isSecondary && 'secondary')}
      className={cn(styles.button, props.className)}
      onClick={isLoading ? (a) => a : onClickHandler}
      size={props.size || 'large'}
      type={(isLoading ? 'button' : props.type) || 'button'}
    >
        {!isLoading && props.children}
        {isLoading && (
          <CircularProgress
            className={cn(styles.submitLoading, { [styles['submitLoading--dark']]: isSecondary })}
            size={20}
          />
        )}
    </Button>
  );
}
