import { useRef } from 'react';
export default function useMap() {
  var map = useRef(new Map());
  return {
    set: function set(key, value) {
      return map.current.set(key, value);
    },
    has: function has(key) {
      return map.current.has(key);
    },
    get: function get(key) {
      return map.current.get(key);
    }
  };
}