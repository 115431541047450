import api from 'apis';

/**
 * Create Zeel member account
 */
export const createMember = async (data: { email: string, password: string, mobile?: string, firstName: string, lastName: string, zip: string, }) => {
  const body: any = {
    object: {
      email: data?.email,
      fname: data?.firstName,
      lname: data?.lastName,
      password: data?.password,
      password1: data?.password,
      zip: data?.zip,
    }
  };
  if (data?.mobile) body['mobile'] = data?.mobile;

  const response = await api.post({
    path: `members/create`,
    version: 'og-cms',
    auth: true,
    data: body
  });

  return response?.response;
}

export const linkMember = async (data: { patientId: string, memberId: string, }) => {
  const body: any = {
    patient_id: data.patientId,
    member_id: data.memberId,
  }
  const response = await api.post({
    path: `members/link_patient`,
    version: 'og-cms',
    auth: true,
    data: body
  });

  return response?.response;
}

export const searchMembers = async (params: any = {}) => {
  const { name } = params;

  const response = await api.get({
    path: `shared/search/member`,
    version: 'og-cms-ctrl',
    auth: true,
    data: {
      term: name
    }
  });

  return response?.response;
}

// export interface MemberType {
  
// }