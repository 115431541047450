import AddReferral from './AddReferral';
import CopyToHsrm from './CopyToHsrm';
import CreateMemberAccount from './CreateMemberAccount';
import LinkPatientToMember from './LinkPatientToMember';

export default {
  "add-referral": AddReferral,
  "copy-to-hsrm": CopyToHsrm,
  "create-member-account": CreateMemberAccount,
  "link-patient-to-member": LinkPatientToMember,
};
