import React, { useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { useState, useDebouncedCallback } from 'utils/hooks';
import { Icon } from 'components';
import {  TextField, InputAdornment } from "@material-ui/core";
import { DataGrid/*, ColDef, CellParams, CellValue*/ } from '@material-ui/data-grid';
import styles from './style.module.scss';

export type TablePropType = {
  loading?: boolean,
  name?: string,
  columns: Array<ColumnType>,
  rows: Array<any>, // any since structure varies depending on columns 
  onRowClick?: Function,
  activeRowId?: string,
  allowSearch?: boolean,
  allowSort?: boolean,
  localSearch?: boolean,
  onSearchChange?: Function,
  onSortChange?: Function,
  autoHeight?: boolean,
  footer?: boolean,
  className?: string,
  serverPagination?: boolean,
  serverRowCount?: any,
  onPageChange?: any,
  onPageSizeChange?: any,
  page?: any,
  pageSize?: any,
  rowsPerPageOptions?: any,
  onSortModelChange?: any,
}

type ColumnType = {
  id: string,
  label: string,
  width?: number,
  description?: string,
  type?: string,
  getter?: Function, 
  formatter?: Function,
  sort?: Function,
  sortable?: boolean,
  renderCell?: Function,
}

export default function _Table({
  loading,
  name,
  columns,
  rows,
  onRowClick,
  activeRowId,
  allowSearch = true,
  allowSort = true,
  localSearch = false,
  onSearchChange,
  onSortChange,
  autoHeight = false,
  footer = true,
  serverPagination = false,
  serverRowCount,
  onPageChange,
  pageSize,
  page,
  onPageSizeChange,
  className,
  rowsPerPageOptions,
  onSortModelChange,
}: TablePropType) {
  const [searchTerm, setSearchTerm] = useState<string>();
  const rowClickHandler = (row: any) => {
    if (onRowClick) {
      onRowClick(row.data)
    }
  }
  const searchHandler = useDebouncedCallback((value) => {
    if (onSearchChange) onSearchChange(value);
  }, 600);

  useEffect(() => {
    const allRowNodes = document.getElementsByClassName('MuiDataGrid-row');
    for (let n of (allRowNodes as any)) {
      n.classList.remove(styles.tableRowActive)
    }
    const activeRowNode = document.querySelectorAll(`[data-id='${activeRowId}']`)?.[0];
    if (activeRowNode) activeRowNode.classList.add(styles.tableRowActive)
  }, [activeRowId])

  const gridColumns = (columns || []).map((column, i) => {
    const { id, label, width, description, type, getter, formatter, sort, sortable = true, renderCell } = column;
    let data: any = {
      field: id,
      headerName: label,
      description,
      headerClassName: styles.tableHeaderCell,
      sortable,
      cellClassName: () => cn(styles.tableCell)
    };
    if (formatter) data['valueFormatter'] = formatter;
    if (getter) data['valueGetter'] = getter;
    if (sort) data['sortComparator'] = sort;
    if (width) data['width'] = width;
    if (renderCell) data['renderCell'] = renderCell;
    if (type === 'moment') {
      data = {
        ...data,
        valueFormatter: ({ value }: any) => value.format('MM/DD/YYYY'),
        sortComparator: (v1: any, v2: any) => v1.valueOf() - v2.valueOf()
      };
    } else if (type === 'status') {
      data = {
        ...data,
        renderCell: ({ value }: any) => {
          if (value === 'alert' || value === false) return <Icon name="exclamation" color="error" size={25} />
          if (value === 'valid' || value === true) return <Icon name="checkmark-circle" color="lush" size={25}  />
        }
      }
    } else if (type) {
      data['type'] = type;
    }
    return data;
  });
  let gridRows: Array<any> = [...rows];

  if (localSearch && searchTerm) {
    if (typeof localSearch === "function") {
      gridRows = gridRows.filter(localSearch);
    } else {
      gridRows = gridRows.filter((row) => {
        let query = '';
        gridColumns.forEach(gridColumn => {
          const val = (row[gridColumn.field] || '');
          if (val instanceof moment) query += (val as moment.Moment).format('MM/DD/YYYY');
          else if (val.toString) query += val.toString().toLowerCase();
        });
        return query.includes((searchTerm || '').toLowerCase());
      })
    }
  }

  return (
    <div className={cn(styles.tableWrapper, className)}>
      {(name || allowSearch) && (
        <div className={styles.topContent}>
          <p className={styles.referralAmount}>{name}</p>
          <div className={styles.fieldsWrapper}>
            {allowSearch && (
              <TextField 
                placeholder="Search"
                size="small"
                onChange={(e) => { setSearchTerm(e.target.value); searchHandler.callback(e.target.value); } }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon color="grey-5" name="search" size={28} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {/* {allowSort && (
              <Select
                value="mostRecent"
                onChange={sortHandler}
              >
                <MenuItem value="mostRecent">Newest First</MenuItem>
              </Select>
            )} */}
          </div>
        </div>
      )}
      <DataGrid
        className={styles.table}
        loading={loading}
        sortingOrder={['asc', 'desc']}
        autoPageSize
        pageSize={pageSize}
        page={page}
        onPageSizeChange={onPageSizeChange}
        hideFooter={!footer}
        autoHeight={autoHeight}
        disableSelectionOnClick
        rows={gridRows}
        columns={gridColumns}
        onRowClick={rowClickHandler}
        paginationMode={serverPagination ? 'server' : 'client'}
        sortingMode={serverPagination ? 'server' : 'client'}
        rowCount={serverRowCount}
        onPageChange={onPageChange}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </div>
  )
}