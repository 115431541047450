import React from 'react';
import { useState, useEffect, useDispatch } from 'utils/hooks';
import { MedicalApi } from 'apis';
import { UIStore } from 'store';
import PatientForm from 'forms/Patient';
// import { PatientType } from 'apis/medical';
import { Button, SidePanel, ServiceRequestList } from 'components';
import styles from './style.module.scss';

export type PatientSidePanelPropType = {
  open?: boolean,
  onClose?: Function,
  id: string, // the patient id,
}

export default function Patient({ open, onClose, id }: PatientSidePanelPropType) {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string | null>();
  const [ /*patientForm*/, setPatientForm ] = useState<any>();
  const [ patient, setPatient ] = useState<any>();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setError(null);
    setLoading(true);
    try {
      const patient = await MedicalApi.fetchPatient(id);
      setPatient(patient);
    } catch (error) {
      setError('An error occured while fetching the patient');
      console.error(error);
      setPatient(null);
    }
    setLoading(false);
  }

    // fetch data on mount
  useEffect(() => {
    if (id) fetchData();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const close = () => {
    if (onClose) {
      onClose();
    }
  }

  const openCreateMemberAccountModal = () => {
    dispatch(UIStore.openModal({
      id: 'create-member-account',
      props: {
        email: patient?.email,
        firstName: (patient?.given_names || []).join(' '),
        lastName: patient?.family_name,
        mobile: patient?.phone, // might not be mobile! TODO: add proper check
        autoGeneratePassword: true
      },
      callback: (createdMember: any) => { if (createdMember) openLinkPatientToMember(createdMember?.id) }
    }));
  }

  const openLinkPatientToMember = (memberId = null) => {
    dispatch(UIStore.openModal({
      id: 'link-patient-to-member',
      props: {
        patientId: patient?.id,
        memberId,
      },
      callback: () => { fetchData() }
    }));
  }

  return (
    <SidePanel
      title="Patient Record"
      loading={loading}
      alert={error}
      open={open}
      onClose={close}
    >
      <PatientForm patient={patient} marginBottom formRef={(form: any) => setPatientForm(form)} />

      <div className={styles.actions}>
        <Button variant={'outlined'} color="primary" size="large" onClick={openCreateMemberAccountModal}>Create Zeel Member account</Button>
        <Button variant={'outlined'} color="primary" size="large" onClick={openLinkPatientToMember}>Link Patient to Member</Button>
      </div>

      <ServiceRequestList serviceRequests={patient?.service_requests} />
    </SidePanel>
  )
}