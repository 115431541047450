import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthApi } from 'apis';
import { c } from 'utils';
// import { TokenType } from 'apis/auth';

const version = 1;
const initialState = {
  token: null,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action) => {
      const { access, refresh, expiresAt, user } = action.payload; 
      state.token = {
        access,
        refresh,
        expiresAt,
        user: {
          ...user,
          roles: [c.ROLES.MEDICAL.ADMIN]
        },
      }
    },
    clearSession: () => {
      return initialState;
    },
  }
});

const { name, actions, reducer } = auth;

// Actions
export const { setSession, clearSession } = actions;

// Thunks
export const login = (email, password) => (
  async (dispatch) => {
    // might be 2 calls: first code, then get token
    try {
      const token = await AuthApi.signIn(email, password);
      if (token) {
        const { access, refresh, expiresAt, user } = token;
        await dispatch(setSession({ access, refresh, expiresAt, user }));
      }
    } catch (error) {
      return new Error(error);
    }
  }
)

export const loginWithCode = (code) => (
  async (dispatch) => {
    try {
      const token = await AuthApi.authenticateWithCode(code);
      if (token) {
        const { access, refresh, expiresAt, user } = token;
        await dispatch(setSession({ access, refresh, expiresAt, user }));
      }
    } catch (error) {
      return new Error(error);
    }
  }
)

export const logout = () => (
  async (dispatch) => {
    dispatch(clearSession());
  }
)

export const refreshToken = () => (
  async (dispatch, getState) => {
    const refresh = getState()[name].token?.refresh;
    if (!refresh) return;
    try {
      const newToken = await AuthApi.refreshToken(refresh);
      if (newToken) {
        const { access, refresh, expiresAt, user } = newToken;
        await dispatch(setSession({ access, refresh, expiresAt, user }));
      }
    } catch (error) {
      return new Error(error);
    }
  }
)

// Selectors
export const isAuthenticated = state => {
  const token = state[name].token;
  if (!token || !token.access || !token.refresh || !token.expiresAt) return false;

  const isExpired = token.expiredAt < new Date().getTime();
  return !isExpired;
}

export const isTokenExpired = state => {
  const token = state[name].token;
  if (!token) return false;

  const { expiresAt } = token || {};
  return expiresAt < new Date().getTime();
}

// return true if at least 1 role matches. Will always return true if user has c.ROLE.ADMIN role
export const hasRole = (roles = []) => {
  return (state) => {
    const rolesList = (Array.isArray(roles) ? roles : [roles]).flat();
    const userRoles = (state[name].token?.user?.roles || []);
    if (rolesList.length === 0) return true;
    if (userRoles.includes(c.ROLES.ADMIN)) return true;
    let allowed = false;
    rolesList.forEach(role => {
      if (userRoles.includes(role)) allowed = true;
    });
    return allowed;
  }
}

export const selectToken = state => state[name].token;
export const selectUser = state => state[name].token?.user;

// Reducer
export default persistReducer({
  key: 'zeel:cms:auth',
  version,
  storage
}, reducer);
