import api from 'apis';
import _ from 'lodash';
// import { mockFhirServiceRequest, mockFhirServiceRequests, mockReferrals, mockAppointments } from './mock';
import { transformApiServiceRequests, transformApiPatients } from './helper';

/**
 * Fetch all facilities.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 */
export const fetchFacilities = async () => {
  const facilities = await api.get({
    path: 'zref/v1/facilities',
    auth: true,
  });
  const sortedFacilities = _.orderBy(facilities, ['name'],['asc']);
  return sortedFacilities;
}

/**
 * Uploads a binary pdf to s3 and returns document uri.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 */
export const uploadDocument = async (file: File) => {
  const response = await api.put({
    path: 'zref/v1/documents',
    auth: true,
    headers: {
      'Content-Type': 'application/pdf'
    },
    data: file
  });
  return response.location;
}

/**
 * Calls zref to ingest a referral in the system. The referral will then
 * take 5 minutes to be visible as a service-request from the medical_massage api.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 **/
export const createReferral = async (referral: ReferralType) => {
  const response = await api.post({
    path: 'zref/v1/referrals',
    auth: true,
    data: referral,
  });
  return response;
}

/**
 * Fetch all referrals that are being ingested.
 * Refer to: https://ng.dev.zeel.com/zref/v1/ui/#/
 */
export const fetchReferrals = async (params: { icn?: string }) => {
  const { icn } = params || {};
  const data: any = {};
  if (icn) data['icn'] = icn;
  const response = await api.get({
    path: 'zref/v1/referrals',
    auth: true,
    // mock: mockReferrals(1),
  });

  return response;
}

/**
 * Fetch all service requests previously ingested from zref.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchServiceRequests = async (params: { icn?: string }) => {
  const { icn } = params || {};
  const data: any = {};
  if (icn) data['icn'] = icn;
  const response = await api.get({
    path: 'medical_massage/v1/service-requests',
    auth: true,
    data,
    // mock: mockFhirServiceRequests(50),
  });

  const transformedServiceRequests = transformApiServiceRequests(response);
  return transformedServiceRequests;
}

/**
 * Fetch a service request by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchServiceRequest = async (serviceRequestId: string) => {
  const response = await api.get({
    path: `medical_massage/v1/service-requests/${serviceRequestId}`,
    auth: true,
  });
  const transformedServiceRequest = transformApiServiceRequests(response);
  return transformedServiceRequest;
}

/**
 * Update a service request by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const updateServiceRequest = async (serviceRequestId: string, serviceRequest: ServiceRequestType) => {
  const response = await api.patch({
    path: `medical_massage/v1/service-requests/${serviceRequestId}`,
    auth: true,
    data: serviceRequest
  });
  return response;
}

/**
 * Fetch all patients.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchPatients = async (params: any = {}) => {
  const { limit, offset, search } = params;
  const patients = await api.get({
    path: 'medical_massage/v1/patient',
    auth: true,
    data: {
      limit,
      offset,
      filter_by: search,
    }
  });
  const transformedPatients = transformApiPatients(patients || []);
  return transformedPatients;
}

/**
 * Fetch patient by id.
 * Refer to: https://ng.dev.zeel.com/medical_massage/v1/ui/#
 */
export const fetchPatient = async (patientId: string) => {
  const response = await api.get({
    path: `medical_massage/v1/patient/${patientId}`,
    auth: true,
  });
  const patient = response?.patient;
  if (patient) {
    const transformedPatient = transformApiPatients(patient);
    return transformedPatient;
  }
}

/**
 * Fetch all appointments
 */
export const fetchAppointmentsToCopy = async ({ page = 1, search = null }) => {
  const response = await api.get({
    path: `appointments/va_medical_reporting`,
    version: 'og-cms',
    auth: true,
    data: {
      page,
      name: search,
    }
  });
  return {
    appointments: response?.response?.appointments,
    total: response?.response?.total_appointments,
  };
}

export const fetchAppointmentNotes = async (appointmentId: string) => {
  const response = await api.get({
    path: `medical_massage/v1/?????/${appointmentId}`,
    auth: true,
    mock: 'Some medical notes', // TODO: remove mock when ready 
  });
  return response;
}

export const setAppointmentAsCopied = async (appointmentId: string) => {
  const response = await api.get({
    path: `appointments/copied_into_hsrm`,
    version: 'og-cms',
    auth: true,
    data: {
      appointment_id: appointmentId
    },
  });
  return response;
}

export const setAppointmentNotesAsReviewed = async (appointmentId: string) => {
  const response = await api.get({
    path: `appointments/note_reviewed`,
    version: 'og-cms',
    auth: true,
    data: {
      appointment_id: appointmentId
    },
  });
  return response;
}

// TYPES
export interface PatientType {
  id: string,
  active: boolean,
  birth_date: string,
  gender: string,
  referral_number?: string,
  edipi: string,
  icn: string,
  member_id?: string,
  given_names: Array<string>,
  family_name: string,
  address: any,
  email?: string,
  phone?: string,
  ssn?: string,
  service_requests?: Array<ServiceRequestType>,
}

export interface ApiPatientType {
  id: string,
  active: boolean,
  resource_type: string,
  address: Array<{
    city?: string,
    line?: Array<string>,
    postal_code?: string,
    state?: string,
  }>,
  birth_date?: string,
  communication?: any,
  gender?: string,
  identifier?: Array<{
    system: string,
    value: any,
  }>,
  name: Array<{
    given: Array<string>,
    family: string,
  }>,
  telecom?: Array<{
    system: string,
    value: any,
  }>,
  service_requests?: Array<ApiServiceRequestType>,
}

export interface ServiceType {
  charges?: number,
  duration?: number, // in minutes
  description?: string,
}

export interface ReferralType {
  referral_id?: string,
  referral_number: string,
  type: string,
  issue_date: string, // YYYY-MM-DD
  expiration_date: string, // YYYY-MM-DD
  facility_id: string,
  insurer: string,
  medical_documentation?: string,
  document_url: string,
  patient: {
    address: {
      line_1?: string,
      line_2?: string,
      locality?: string,
      postal?: string,
      region?: string,
    },
    icn: string,
    edipi: string,
    given_name: any,
    family_name: string,
    birth_date: string,
    ssn?: string,
    phone: string,
    email?: string
    mobile?: string,
    gender?: string,
  },
  services: Array<ServiceType>,
}

export interface ApiServiceRequestType {
  id: string,
  authored_on: string,
  code?: {
    coding?: Array<{
      code?: string,
      display?: string,
      system?: string,
    }>,
  },
  consent?: Array<{type: string, url?: string,}>,
  identifier?: Array<{
    system: string,
    value: any,
  }>,
  insurance: Array<{insuror: string,}>,
  intent: string,
  note: Array<{text: string, time: string,}>,
  occurrence_period: {
    start?: string,
    end?: string,
  },
  quantity: {
    value: number,
    available: number,
    completed?: number,
    planned?: number,
  },
  status: string,
  subject: {
    reference: string,
    type: string,
  },
  resource_type: string,
}

export interface ServiceRequestType {
  id?: string,
  patient_id?: string,
  issue_date: string, // YYYY-MM-DD
  expiration_date: string, // YYYY-MM-DD
  consent?: Array<{type: string, url?: string,}>,
  note: Array<{text: string, time: string,}>,
  status: string,
  insuror?: string,
  referral_number?: string,
  quantity: {
    value: number,
    available: number,
    completed?: number,
    planned?: number,
  },
  created_at: string,
}

export interface FacilityType {
  facility_id: string,
  name: string,
  phone?: string,
  created_at?: string,
  updated_at?: string,
}

export interface AppointmentType {
  id: string,
  address?: {
    address1: string,
    address2?: string,
    city: string,
    state: string,
    zip: string,
  },
  provider?: {
    fname: string,
    lname: string,
    id: string,
    npi: string,
  },
  duration: string | number,
  copied_into_hsrm: boolean,
  fname: string,
  lname: string,
  birthday?: string,
  stage?: string,
  service_request_id: string,
  provider_id?: string,
  date?: string,
}