import api from './api';
import * as AuthApi from './auth';
import * as MedicalApi from './medical';
import * as MemberApi from './member';

export {
  AuthApi,
  MedicalApi,
  MemberApi,
};

export default api;
