const constants: any = {
  ROLES: { // need to match cognito group name, which translates in UI roles
    ADMIN: 'admin',
    MEDICAL: {
      DATA_ENTRY: 'medical-data-entry',
      CUSTOMER_SERVICE: 'medical-customer-service',
      REVIEWER: 'medical-reviewer',
      ADMIN: 'medical-admin',
      ALL: ['medical-data-entry', 'medical-customer-service', 'medical-reviewer', 'medical-admin'],
    },
    TEST: 'test',
  }
};

export default constants;
