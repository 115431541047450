import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MedicalApi } from 'apis';

const initialState = {
  loading: true,
  error: null,
  referrals: [], // fetch referrals, and save the ones that are not processed yet
  serviceRequests: [],
  patients: [],
};

export const fetchReferralsAndServiceRequests = createAsyncThunk('medical/fetchServiceRequests',
  async (userId, thunkAPI) => {
    try {
      // TODO: should be a promise.all
      const referrals = await MedicalApi.fetchReferrals();
      const serviceRequests = await MedicalApi.fetchServiceRequests();
      return {
        referrals,
        serviceRequests
      };
    } catch (error) {
      console.error(error);
      throw error;
    }  
  }
)

// export const fetchPatients = createAsyncThunk('medical/fetchPatients',
//   async (userId, thunkAPI) => {
//     try {
//       const patients = await MedicalApi.fetchPatients();
//       return {
//         patients,
//       };
//     } catch (error) {
//       console.error(error);
//     }  
//   }
// )

export const slice = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [fetchReferralsAndServiceRequests.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [fetchReferralsAndServiceRequests.fulfilled]: (state, action) => {
      state.referrals = action.payload?.referrals || [];
      state.serviceRequests = action.payload?.serviceRequests || [];
      state.loading = false;
      state.error = false;
    },
    [fetchReferralsAndServiceRequests.rejected]: (state) => {
      state.loading = false;
      state.error = "An error occured while fetching referrals."
    },

    // [fetchPatients.pending]: (state) => {
    //   state.loading = true;
    //   state.error = false;
    // },
    // [fetchPatients.fulfilled]: (state, action) => {
    //   state.patients = action.payload?.patients || [];
    //   state.loading = false;
    //   state.error = false;
    // },
    // [fetchPatients.rejected]: (state) => {
    //   state.loading = false;
    //   state.error = "An error occured while fetching patients."
    // },
  }
});

const { name, /*actions,*/ reducer } = slice;

// Actions
// export const { someAction } = actions;

// Thunks
// export const fetchReferralsAndServiceRequests = () => (
//   async (dispatch) => {
//     try {
//       const referrals = await MedicalApi.fetchReferrals();
//     } catch (error) {
//       return new Error(error);
//     }
//   }
// )

// Selectors
export const isLoading = state => {
  return state[name].loading;
}
export const getReferrals = state => {
  return state[name].referrals || [];
}
export const getServiceRequests = state => {
  return state[name].serviceRequests || [];
}
// export const getPatients = state => {
//   return state[name].patients || [];
// }

// Reducer
export default reducer;
