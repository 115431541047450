import { ApiServiceRequestType, ApiPatientType } from './';

export function transformApiServiceRequests(apiServiceRequests: Array<ApiServiceRequestType> | ApiServiceRequestType) {
  let serviceRequestsToTransform: Array<ApiServiceRequestType> = [];
  if (!Array.isArray(apiServiceRequests)) serviceRequestsToTransform.push(apiServiceRequests);
  else serviceRequestsToTransform = [...apiServiceRequests];

  const transformed = (serviceRequestsToTransform || []).map(apiServiceRequest => {
    const { id, authored_on, identifier, occurrence_period, insurance, note, consent, quantity, status, subject } = apiServiceRequest;
    const { start, end } = occurrence_period || {};
    const patient_id = subject?.type === 'Patient' ? ((subject?.reference || '').split('/') || []).slice(-1).pop() : null;
    const referral_number = identifier?.find(i => i.system.includes('referral-number'))?.value;
    const insuror = insurance?.[0]?.insuror;

    return {
      id,
      patient_id,
      issue_date: start,
      expiration_date: end,
      consent,
      note,
      status: status,
      insuror,
      referral_number,
      quantity,
      created_at: authored_on,
    }
  })

  if (!Array.isArray(apiServiceRequests)) return transformed[0];
  else return transformed;
}

export function transformApiPatients(apiPatients: Array<ApiPatientType> | ApiPatientType) {
  let patientsToTransform: Array<ApiPatientType> = [];
  if (!Array.isArray(apiPatients)) patientsToTransform.push(apiPatients);
  else patientsToTransform = [...apiPatients];

  const transformed = (patientsToTransform || []).map(apiPatient => {
    const { id, active, address, birth_date, telecom, /*communication,*/ gender, identifier = [], name, service_requests } = apiPatient;
    const family_name = name[0]?.family;
    const given_names = name[0]?.given || [];
    const referral_number = identifier.find(i => i.system.includes('referral-number'))?.value;
    const edipi = identifier.find(i => i.system.includes('edipi'))?.value;
    const icn = identifier.find(i => i.system.includes('icn'))?.value;
    const member_id = identifier.find(i => i.system.includes('member_id'))?.value;
    const email = telecom?.find(i => i.system === 'email')?.value;
    const phone = telecom?.find(i => i.system === 'phone')?.value;
    const ssn = identifier.find(i => i.system.includes('last-4'))?.value;

    return {
      id,
      active,
      address,
      birth_date,
      gender,
      referral_number,
      edipi,
      icn,
      member_id,
      given_names,
      family_name,
      email,
      phone,
      service_requests: transformApiServiceRequests(service_requests || []),
      ssn,
    }
  })

  if (!Array.isArray(apiPatients)) return transformed[0];
  else return transformed;
}