import React from 'react';
import { useState } from 'utils/hooks';
import { SidePanel } from 'components';
// import styles from './style.module.scss';

export type ReferralSidePanelPropType = {
  open?: boolean,
  onClose?: Function,
  id?: string, // the referral/service-request id,
  type?: "referral" | "service-request",
}

export default function Referral({ open, onClose, id, type }: ReferralSidePanelPropType) {
  const [ state, setState ] = useState({ editing: false });
  const { editing } = state;
  // const edit = () => {
  //   setState({ ...state, editing: true });
  // }
  // const saveEdit = () => {
  //   setState({ ...state, editing: false });
  //   if (onClose) {
  //     onClose();
  //   }
  // }
  // const discardEdit = () => {
  //   setState({ ...state, editing: false });
  // }
  const close = () => {
    setState({ ...state, editing: false });
    if (onClose) {
      onClose();
    }
  }

  return (
    <SidePanel
      title="Medical Referral Record"
      open={open}
      warnBeforeClosing={editing}
      onClose={close}
    >
      <p>The {type}: {id}</p>
    </SidePanel>
  )
}