import React from 'react';
import cn from 'classnames';
import Icon from '../Icon';
import styles from './style.module.scss';

export type FieldTitlePropType = {
  icon?: string,
  children?: React.ReactNode,
  rightContent?: React.ReactNode,
  margin?: boolean,
}

export default function FieldTitle({ icon, children, rightContent, margin }: FieldTitlePropType) {
  return (
    <div className={cn(styles.base, { [styles.margin]: margin })}>
      <div className={styles.content}>
        {icon && <Icon name={icon} className={styles.icon} />}
        <p className={styles.title}>{children}</p>
      </div>
      <div>
        {rightContent}
      </div>
    </div>
  );
}
