import React from 'react';
import { Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { history, routes, c } from 'utils';
import theme from 'styles/theme';
import { Route, Idler, AlertManager } from 'components';
import ModalManager from 'modals';

import { Login, Home, Medical } from 'pages'; // disabled lazy loading since it doesn't work well with custom Route component and Redirect

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route public path={routes.LOGIN_ROOT} component={Login} />
            <Route path={routes.HOME} component={Home} />
            <Route path={routes.MEDICAL} component={Medical} role={[c.ROLES.MEDICAL.ALL]} />
            <Redirect to={routes.DEFAULT} />
          </Switch>
        </Router>
        <Idler />
        <ModalManager />
        <AlertManager />
      </ThemeProvider>
    </div>
  );
}

export default App;
