import React, { useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { UIStore } from 'store';
import { useSelector, useDispatch } from 'utils/hooks';
import styles from './style.module.scss';

type AlertProps = {
  id: string,
  title: string,
  severity?: "success" | "info" | "warning" | "error" | undefined,
  duration?: number,
  onClose: Function,
}
function AlertWrapper(props: AlertProps) {
  const { title, severity = 'success', duration = 5000, onClose } = props;
  useEffect(() => {
    if (onClose) {
      setTimeout(() => {
        onClose();
      }, duration)
    }
  }, [duration, onClose])
  return (
    <Alert className={styles.alert} severity={severity}>{title}</Alert>
  )
}
export default function AlertManager() {
  const dispatch = useDispatch();
  const alerts = useSelector(UIStore.getAlerts);
  const closeAlert = (id: string) => {
    dispatch(UIStore.closeAlert({ id }));
  }

  return (
    <div className={styles.alertsContainer}>
      {alerts.map((alert: AlertProps) => {
        const { id } = alert;
        return <AlertWrapper key={id} {...alert} onClose={() => closeAlert(id)} />
      })}
    </div>
  );
}