import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
// import styles from './style.module.scss';

export type TextFieldPropType = TextFieldProps;

export default function _TextField(props: any) {  // TODO: fix types
  return (
    <TextField {...props} error={!!props.error} helperText={props.error} />
  );
}
