import React from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import { Button, LinearProgress } from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import styles from './style.module.scss';

export type SidePanelPropType = {
  open?: boolean,
  warnBeforeClosing?: boolean,
  title?: string,
  loading?: boolean,
  alert?: string | null,
  alertSeverity?: "error" | "success" | "info" | "warning" | undefined,
  onClose?: Function,
  showCloseIcon?: boolean,
  actions?: Array<ActionType>,
  children?: React.ReactNode,
}

type ActionType = {
  label: string,
  onClick?: (e: any) => void, // issues with button type when passing type function, TO FIX
  main?: boolean,
  disabled?: boolean,
}

export default function SidePanel({ loading, open, warnBeforeClosing, title, onClose, showCloseIcon = true, actions, alert, alertSeverity = "error", children }: SidePanelPropType) {
  const close = async () => {
    if (warnBeforeClosing) {
      const warningMessage = typeof warnBeforeClosing === 'string' ? warnBeforeClosing : 'If you close this view, you will lose all unsaved changed. Are you sure you want to close?';
      const response = await window.confirm(warningMessage);
      if (!response) return;
    }
    if (onClose) {
      onClose();
    }
  }
  
  return (
    <div className={cn(styles.container, { [styles['container--hidden']]: !open })}>
      <div className={styles.inner}>
        {alert && (
          <div className={styles.alertWrapper}>
            <Alert className={styles.alert} severity={alertSeverity}>{alert}</Alert>
          </div>
        )}
        <div className={styles.header}>
          <div>
            {title && <p className={styles.title}>{title}</p>}
          </div>
          <div>
            {/* <Icon className={styles.icon} name="dot-menu-horizontal" /> */}
            {showCloseIcon && <Icon className={styles.icon} name="x" onClick={close} />}
          </div>
        </div>
        <div className={styles.content}>
          {loading && (
            <div className={styles.loaderOverlay}>
              <div className={styles.loader}>
                <LinearProgress />
              </div>
            </div>
          )}
          {children}
        </div>
        {actions && (
          <div className={styles.actions}>
            {(actions || []).map(action => {
              const { label, onClick, main, disabled = false } = action;
              return <Button variant={main ? 'contained' : 'outlined'} type={main ? 'submit' : 'button'} fullWidth color="primary" size="large" disabled={disabled} onClick={onClick}>{label}</Button>;
            })} 
          </div>
        )}
      </div>
      
    </div>
  )
}