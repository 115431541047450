import React from 'react';
import { v4 as uuid } from 'uuid';
import { MemberApi } from 'apis';
import { useForm, useDispatch } from 'utils/hooks';
import { UIStore } from 'store';
import Modal, { ModalPropType } from '../templates/Modal';
import { Grid } from "@material-ui/core";
import { FieldTitle, TextField, PhoneField } from 'components';
import styles from './style.module.scss';

export type CreateMemberAccountProps = ModalPropType & {
  email?: string,
  firstName?: string,
  lastName?: string,
  mobile?: string,
  autoGeneratePassword?: boolean,
};

export default function CreateMemberAccount({ autoGeneratePassword, email, firstName, lastName, mobile, onClose, scrollTop, modalProps }: CreateMemberAccountProps) {
  const dispatch = useDispatch();
  const { bind, form } = useForm({
    email,
    firstName,
    lastName,
    mobile,
    password: autoGeneratePassword ? uuid() : null,
  });

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  }

  const onSubmit = async (values: any) => {
    try {
      const createdMember = await MemberApi.createMember({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        mobile: values.mobile,
        zip: values.zip,
      });

      dispatch(UIStore.openAlert({ title: `Member Created. The member ID ${createdMember?.id} was copied to the clipboard.`, duration: 15000 }));
      navigator.clipboard.writeText(createdMember?.id);
      if (onClose) (onClose as Function)(createdMember);
    } catch (error) {
      form.setFormError(`An error occured`);
      scrollTop();
    }
  }

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    }
  }
 
  return (
    <Modal
      {...modalProps}
      alert={form.formError}
      title="Create New Zeel Account"
      description={<>If the patient doesn't have a Zeel account to link to,<br/> you can create a new one here.</>}
      className={styles.base}
      backdropClose={false}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Cancel', action: "close" },
        { label: 'Create New Account', action: "submit" },
      ]}
    > 
      <Grid className={styles.veteranFields} container spacing={3}>
        <Grid item xs={12}><FieldTitle icon="orientation-card">New Zeel Account Information</FieldTitle></Grid>
        <Grid item xs={6}><TextField {...bind('firstName', { required: true })} label="First Name" autoFocus {...fieldProps} /></Grid>
        <Grid item xs={6}><TextField {...bind('lastName', { required: true })} label="Last Name" {...fieldProps} /></Grid>
        <Grid item xs={6}><TextField {...bind('email', { required: true })} label="Email" {...fieldProps} /></Grid>
        <Grid item xs={6}><TextField {...bind('password', { required: false })} disabled={autoGeneratePassword} label="Password" type="password" {...fieldProps} /></Grid>
        <Grid item xs={6}><PhoneField {...bind('mobile', { required: false })} label="Mobile Phone Number" {...fieldProps} /></Grid>
        <Grid item xs={6}><TextField {...bind('zip', { required: true })} label="Zip Code" {...fieldProps} /></Grid>
      </Grid>
    </Modal>
  );
}
