const routes = {
  LOGIN: `/login`,
  LOGIN_ROOT: `/login/:code?`,

  DEFAULT: `/medical`, // only while there is no home page
  HOME: `/home`,
  MEDICAL: `/medical`,
  MEDICAL_DASHBOARD: `/medical/dashboard`,
  MEDICAL_REFERRALS: `/medical/referrals`,
  MEDICAL_PATIENTS: `/medical/patients`,
  MEDICAL_APPOINTMENTS: `/medical/appointments`,
  MEDICAL_APPOINTMENTS_UPDATE: `/medical/appointments-update`,

  APPOINTMENTS: `/appointments`,
  PROVIDERS: `/providers`,
  CUSTOMERS: `/customers`,
  BUSINESSES: `/businesses`,
  SETTINGS: `/settings`,

  PRIVATE_ROUTE_KICKBACK: `/login`,
};

export default routes;
