import axios from 'axios';
import store from 'store';
import { AuthStore } from 'store';
import { selectToken, selectUser, isTokenExpired } from 'store/Auth';

export type apiConfig = {
  path?: string,
  version?: "ng" | "ng-auth" | "og" | "og-cms" | "og-cms-ctrl",
  data?: any,
  auth?: boolean,
  headers?: any,
  debug?: boolean,
  mock?: any,
}
const apiModule = async (config: apiConfig = {}, method = 'GET') => {
  const {
    path = '', // either full url to use, or partial, which will be added after the api version domain
    version = 'ng',
    data = {},
    auth = false,
    headers: extraHeaders,
    debug = false,
    mock,
  } = config || {};

  // Retrieving memberId from the logged-in user for og-cms calls
  const stateUser = selectUser(store.getState());
  if (['og-cms', 'og-cms-ctrl'].includes(version) && !stateUser?.memberId) {
    throw new Error('memberId property missing from logged in user object - required for og-cms and og-cms-ctrl api calls.');
  }
  
  const apiVersions = {
    'ng': process.env.REACT_APP_NG_API,
    'ng-auth': process.env.REACT_APP_NG_AUTH_API,
    'og': process.env.REACT_APP_OG_API,
    'og-cms': `${process.env.REACT_APP_OG_API}api/v1/cms/${stateUser?.memberId}/`,
    'og-cms-ctrl':  `${process.env.REACT_APP_OG_API}cms/${stateUser?.memberId}/`,
  };
  let headers = {};

  // Setting request full path using path and version params
  let url = '';
  if (path.includes('http://') || path.includes('https://')) url = path;
  else if (apiVersions[version]) url = `${apiVersions[version]}${path}`;
  else console.error(`Api version '${version}' is invalid, please use ${Object.keys(apiVersions).join('|')}`);

  // Adding parameters for GET request
  let params: any = {};
  if (method === 'GET') params = data;

  // Adding authorization
  let accessToken = null;
  if (auth === true) {
    const stateToken = selectToken(store.getState());
    if (stateToken) {      
      if (isTokenExpired(store.getState())) {
        await store.dispatch(AuthStore.refreshToken());
        const updatedStateToken = selectToken(store.getState()); // TODO: check if it works
        accessToken = updatedStateToken?.access; // TODO: check if it works
      } else {
        accessToken = stateToken.access;
      }
    }
  } else if (typeof auth === 'string') { // use provided token instead
    accessToken = auth;
  }
  if (accessToken) {
    (headers as any)['Authorization'] = accessToken;
  }

  // Add api key for OG calls
  if (version === 'og') {
    params['api_key'] = process.env.REACT_APP_OG_API_KEY;
  }

  // Setting request headers
  (headers as any)['Content-Type'] = 'application/json';
  if (extraHeaders) {
    headers = {
      ...headers,
      ...extraHeaders
    }
  }

  // Add mock if specified
  if (mock) {
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(500);
    return mock;
  }

  // Building request
  const request: any = { // TODO: remove any and put proper typing
    url,
    method,
    headers,
    data,
    params,
  };

  if (debug) console.log(`Request for '${url}'`, request);
  try {
    const response = await axios(request);
    if (debug) console.log(`Response for '${url}'`, response);
    // Handle OG errors
    if (['og', 'og-cms', 'og-cms-ctrl'].includes(version) && response?.data?.status !== 'ok') {
      throw new Error((response?.data as any)); // TODO: pass proper api error
    }
    return response.data;
  } catch (error) {
    if (debug) console.log(`Response error for '${url}'`, error);
    throw error;
  }
};

export default {
  get: async (config: apiConfig) => await apiModule(config, 'GET'),
  post: async (config: apiConfig) => await apiModule(config, 'POST'),
  patch: async (config: apiConfig) => await apiModule(config, 'PATCH'),
  put: async (config: apiConfig) => await apiModule(config, 'PUT'),
}