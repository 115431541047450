import React from 'react';
import { ServiceRequestType } from 'apis/medical';
import { FieldTitle, Table } from 'components';

export type ServiceRequestListProps = {
  serviceRequests?: Array<ServiceRequestType>,
}

export default function ServiceRequestList({ serviceRequests }: ServiceRequestListProps) {
  const columns = [
    { id: 'referralId', label: 'Referral ID', width: 200,  },
    { id: 'expiration', label: 'Expiration', width: 150 },
    { id: 'appointments', label: 'Appointments', width: 150, },
  ];

  const rows = (serviceRequests || []).map((serviceRequest: ServiceRequestType) => {
    const { id, expiration_date, quantity } = serviceRequest;

    return {
      id,
      referralId: id,
      expiration: expiration_date,
      appointments: `${quantity?.available}/${quantity?.value} remaining`,
    }
  })
  return (
    <div>
      <FieldTitle icon="pad-with-star">Linked Referrals</FieldTitle>
      <Table
        allowSearch={false}
        footer={false}
        columns={columns}
        rows={rows}
        autoHeight
      />
    </div>
  );
}