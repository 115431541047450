import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { routes } from 'utils';
import { Route, Layout } from 'components';
// import styles from './style.module.scss';

import { /*Dashboard,*/ Referrals, Patients,/* Appointments,*/ AppointmentsUpdate } from './sections';

export default function Medical() {
  const tabs = [
    // { label: 'Dashboard', route: routes.MEDICAL_DASHBOARD },
    { label: 'Referrals', route: routes.MEDICAL_REFERRALS },
    { label: 'Patients', route: routes.MEDICAL_PATIENTS },
    // { label: 'Appointments', route: routes.MEDICAL_APPOINTMENTS },
    { label: 'Update Appointments', route: routes.MEDICAL_APPOINTMENTS_UPDATE }
  ];

  return (
    <Layout tabs={tabs}>
      <Switch>
        {/* <Route path={routes.MEDICAL_DASHBOARD} component={Dashboard} /> */}
        <Route path={routes.MEDICAL_REFERRALS} component={Referrals} />
        <Route path={routes.MEDICAL_PATIENTS} component={Patients} />
        {/* <Route path={routes.MEDICAL_APPOINTMENTS} component={Appointments} /> */}
        <Route path={routes.MEDICAL_APPOINTMENTS_UPDATE} component={AppointmentsUpdate} />
        <Redirect to={routes.MEDICAL_REFERRALS} />
      </Switch>
    </Layout>
  )
}