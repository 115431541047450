import { useSelector } from './';
import { isAuthenticated, selectUser } from 'store/Auth';

export default function useAuth() {
  const isAuth = useSelector(isAuthenticated);
  const user = useSelector(selectUser);
  return {
    isAuthenticated: isAuth,
    user,
  };
}