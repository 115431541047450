import React, { useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { FieldTitle } from 'components';

export type FormProps = {
  form?: any,
  formRef?: Function,
  className?: string,
  header?: boolean,
  title?: string,
  icon?: string,
  edit?: boolean,
  marginBottom?: boolean,
  children?: any,
}

export default function Form({ form, formRef, className, header = true, title, icon, marginBottom, children }: FormProps) {
  useEffect(() => {
    if (formRef) formRef(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid className={className} style={{ marginBottom: marginBottom ? (marginBottom === true ? '32px' : marginBottom) : '0' }} container spacing={3}>
      {title && header && <Grid item xs={12}><FieldTitle icon={icon}>{title}</FieldTitle></Grid>}
      {children}
    </Grid>
  );
}