import api from 'apis';
import { helper } from 'utils';
import qs from 'qs';

export const authorize = async () => {
  const redirectUri = process.env.REACT_APP_NG_AUTH_REDIRECTION_URI;
  (window.location as any) = `${process.env.REACT_APP_NG_AUTH_API}/login?response_type=code&client_id=${process.env.REACT_APP_NG_AUTH_CLIENT_ID}&redirect_uri=${redirectUri}`;
}

export const getUserFromIdToken: (idToken: string) => any = (idToken: string) => {
  const cognitoUser = helper.parseJwt(idToken);
  const user = {
    username: cognitoUser?.['cognito:username'],
    roles: cognitoUser?.['cognito:groups'] || [],
    memberId: cognitoUser?.['custom:member_id'],
  }
  return user;
}

export const authenticateWithCode: (code: string) => Promise<TokenType> = async (code: string) => {
  const requestBody = qs.stringify({
    grant_type: 'authorization_code',
    code,
    client_id: process.env.REACT_APP_NG_AUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_NG_AUTH_REDIRECTION_URI,
  });

  const response = await api.post({
    path: '/oauth2/token',
    version: 'ng-auth',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: requestBody
  });

  const { id_token, access_token, refresh_token, expires_in, token_type } = response;
  

  return {
    access: `${token_type} ${access_token}`,
    refresh: refresh_token,
    expiresAt: new Date().getTime() + (expires_in * 1000),
    user: getUserFromIdToken(id_token),
  }
}

export const refreshToken: (token: string) => Promise<TokenType> = async (refreshToken: string) => {
  const requestBody = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: process.env.REACT_APP_NG_AUTH_CLIENT_ID,
  });

  const response = await api.post({
    path: '/oauth2/token',
    version: 'ng-auth',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: requestBody
  });

  const { id_token, access_token, expires_in, token_type } = response;

  return {
    access: `${token_type} ${access_token}`,
    refresh: refreshToken,
    expiresAt: new Date().getTime() + (expires_in * 1000),
    user: getUserFromIdToken(id_token),
  }
}

// Not used for now as we use the hosted cognito login form UI and redirect_uri
export const signIn = async (/*data*/) => {
  // const { email, password } = data;

  // const response = await api.get({
  //   path: '/oauth2/authorize',
  //   version: 'ng-auth',
  //   debug: true,
  // });

  // return {
  //   access: 'Bearer 8q7nc89juc93jrc902',
  //   refresh: 'Bearer im9dm2j309fjnqionmer',
  //   expiresAt: new Date().getTime() + 3600000,
  // }
}


// TYPES
export interface TokenType {
  access: string, // Bearer [token]
  refresh: string, // [token]
  expiresAt: number, // milli
  user: {
    username: string | null,
    groups: Array<string>,
  }
}