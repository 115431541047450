import React, { useEffect } from 'react';
import moment from 'moment';
import { MedicalApi } from 'apis';
import { FacilityType } from 'apis/medical';
import { useState, useForm, useDispatch } from 'utils/hooks';
import { UIStore } from 'store';
import Modal, { ModalPropType } from '../templates/Modal';
import { Grid, Button, MenuItem } from "@material-ui/core";
import { FieldTitle, TextField, PhoneField/*, Checkbox*/ } from 'components';
// import AddressField, { AddressSuggestionType } from 'components/fields/AddressField';
import styles from './style.module.scss';

export type AddReferralProps = ModalPropType & {
  referralFile?: File | null,
}

export default function AddReferral({ referralFile: defaultReferralFile = null, onClose, scrollTop, modalProps }: AddReferralProps) {
  const [loading, setLoading] = useState(true);
  const [facilities, setFacilities] = useState<Array<FacilityType>>([]);
  const [referralFile, setReferralFile] = useState<File | null>(defaultReferralFile);
  const [referralFileError, setReferralFileError] = useState<string | null>();
  // const [addressObject, setAddressObject] = useState<AddressSuggestionType | null>();
  const dispatch = useDispatch();
  const { bind, form } = useForm({
    serviceLength: 60,
    insurer: 'Optum',
    isPolicyHolder: true,
  });

  useEffect(() => {
    async function fetchFacilities() {
      try {
        const response = await MedicalApi.fetchFacilities();
        setFacilities(response);
      } catch (error) {
        console.error('An error occured while fetching the facilities', error);
      }
      setLoading(false);
    }
    fetchFacilities();
  }, [])
  
  const fileUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File = ((e.target as HTMLInputElement).files || [])[0];
    setReferralFile(file);
    setReferralFileError(null);
  }

  // const addressSelected = (address: AddressSuggestionType) => {
  //   form.setField('address', address?.fullAddress); // BUG: doesn't take latest values
  //   setAddressObject(address);
  // }

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  }

  const onSubmit = async (values: any) => {
    // if (!addressObject) {
    //   form.setFieldError('address', 'Invalid address');
    //   return;
    // }
    setReferralFileError(!referralFile ? 'Please upload a pdf' : null);
    if (!referralFile) {
      form.setFormError('Please fix the errors below');
      scrollTop();
      return;
    };

    let documentUri = null;
    try {
      documentUri = await MedicalApi.uploadDocument((referralFile as File));
    } catch (error) {
      form.setFormError('An error occured while uploading the referral pdf');
      scrollTop();
      console.error(error);
      return;
    }

    const givenName = [values.firstName];
    if (values.middleName) givenName.push(values.middleName);
    try {
      // TODO: use the policyHolder values (policyHolderFirstName, policyHolderLastName, policyHolderMiddleName, policyHolderDateOfBirth)
      const createdReferral = await MedicalApi.createReferral({
        referral_number: values.referralNumber,
        type: values.referralNumber.split('-')[0],
        issue_date: values.issueDate,
        expiration_date: values.expirationDate,
        facility_id: values.facilityId,
        insurer: values.insurer,
        medical_documentation: values.documentation,
        document_url: documentUri,
        patient: {
          address: {
            line_1: values.address1 || '',
            line_2: values.unit,
            locality: values.city || '',
            postal: values?.zip || '',
            region: values?.state || '',
            // line_1: addressObject?.line1 || '',
            // line_2: values.unit,
            // locality: addressObject?.locality || '',
            // postal: addressObject?.postalCode || '',
            // region: addressObject?.stateShort || '',
          },
          icn: values.icn,
          edipi: values.edipi,
          given_name: givenName,
          family_name: values.familyName,
          birth_date: values.dateOfBirth,
          ssn: values.ssn,
          phone: values.phone,
          email: values.email || null,
          // mobile: values.mobile || null,
          gender: values.gender,
        },
        services: [
          {
            charges: Number(values.nbServicesTotal),
            duration: values.serviceLength
          }
        ],
      });

      // line_1: "350 N High St"
      // line_2: ""
      // locality: "Columbus"
      // postal: "43215"
      // region: "OH"

      dispatch(UIStore.openAlert({ title: `Referral #${values.referralNumber} Submitted` }));
      if (onClose) (onClose as Function)(createdReferral);
    } catch (error) {
      form.setFormError(`An error occured: ${error?.response?.data?.detail || ''}`);
      scrollTop();
    }
  }

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    }
  }
 
  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={form.formError}
      title="Add New Referral"
      description={<>Start by uploading an SEOC Referral PDF to<br/> pre-fill fields, or enter data manually below.</>}
      className={styles.base}
      backdropClose={false}
      warnBeforeClosing
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Cancel', action: "close" },
        { label: 'Save', action: "submit" },
      ]}
    >
      <div className={styles.uploadButtonContainer}>
        <input accept="application/pdf" onChange={fileUploaded} style={{ display: 'none' }} id="upload-pdf" type="file" />
        <label htmlFor="upload-pdf">
          <Button variant="outlined" size="large" color="primary" component="span">
            Upload Referral PDF
          </Button>
        </label>
        {referralFile && referralFile.name && <p className={styles.fileName}>{referralFile?.name}</p>}
        {referralFileError && <p className={styles.fileError}>{referralFileError}</p>}
      </div>
      
      <div>
        <Grid className={styles.veteranFields} container spacing={3}>
          <Grid item xs={12}><FieldTitle icon="orientation-card">Veteran Information</FieldTitle></Grid>
          <Grid item xs={6}><TextField {...bind('icn', { required: true })} label="Integration Control Number (ICN)" autoFocus {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('edipi', { required: true })} label="EDIPI number" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('firstName', { required: true })} label="First Name" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('middleName', { required: false })} label="Middle Name (Optional)" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('familyName', { required: true })} label="Last Name" {...fieldProps} /></Grid>
          <Grid item xs={6} />
          <Grid item xs={6}><TextField {...bind('dateOfBirth', { required: true })} label="Date of Birth" type="date" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('ssn', { required: true })} inputProps={{ maxLength: 9 }} label="SSN" {...fieldProps} /></Grid>
          <Grid item xs={6}><PhoneField {...bind('phone', { required: true })} label="Phone Number" {...fieldProps} /></Grid>
          <Grid item xs={6} />
          {/* <Grid item xs={6}><AddressField {...bind('address', { required: true, callback: () => setAddressObject(null) })} id='address' onAddress={addressSelected} label="Address" {...fieldProps} /></Grid> */}
          <Grid item xs={6}><TextField {...bind('address1', { required: true })} label="Address" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('unit', { required: false })} label="Unit Number (Optional)" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('city', { required: true })} label="City" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('state', { required: true })} inputProps={{ maxLength: 2 }} label="State" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('zip', { required: true })} inputProps={{ maxLength: 5 }} label="Zip" {...fieldProps} /></Grid>
        </Grid>

        <div className={styles.optionalFields}>
          <Grid container spacing={3}>
            <Grid item xs={12}><p className={styles['optionalFields-description']}>The following optional fields are not on the referral, but may be available in HSRM on the veteran’s account.</p></Grid>
            <Grid item xs={6}><TextField {...bind('email', { required: false })} type="email" label="Email Address (Optional)" {...fieldProps} /></Grid>
            {/* <Grid item xs={6}><PhoneField {...bind('mobile', { required: false })} label="Mobile Phone Number (Optional)" {...fieldProps} /></Grid> */}
            <Grid item xs={6}>
              <TextField {...bind('gender', { required: false })} select label="Veteran Gender (Optional)" {...fieldProps}>
                <MenuItem value="">-</MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </div>

        <Grid className={styles.policyHolderFields} container spacing={3}>
          {/* <Grid item xs={12}><Checkbox {...bind('isPolicyHolder')} label="Patient is the primary insurance policy holder" /></Grid> */}
          {!form.getField('isPolicyHolder') && (
            <>
              <Grid item xs={12}><FieldTitle icon="user">Policy Holder Information</FieldTitle></Grid>
              <Grid item xs={6}><TextField {...bind('policyHolderFirstName', { requiredIf: (values: any) => !values['isPolicyHolder'] })} label="First Name" {...fieldProps} /></Grid>
              <Grid item xs={6}><TextField {...bind('policyHolderMiddleName')} label="Middle Name (Optional)" {...fieldProps} /></Grid>
              <Grid item xs={6}><TextField {...bind('policyHolderLastName', { requiredIf: (values: any) => !values['isPolicyHolder'] })} label="Last Name" {...fieldProps} /></Grid>
              <Grid item xs={6}><TextField {...bind('policyHolderDateOfBirth', { requiredIf: (values: any) => !values['isPolicyHolder'] })} label="Date of Birth" type="date" {...fieldProps} /></Grid>
              {/* <Grid item xs={6}><TextField {...bind('policyHolderSsn', { requiredIf: false })} inputProps={{ maxLength: 4 }} label="Last 4 digits of SSN" {...fieldProps} /></Grid> */}
            </>
          )}
        </Grid>
        

        <Grid className={styles.referralFields} container spacing={3}>
          <Grid item xs={12}><FieldTitle icon="pad-with-star">Referral Information</FieldTitle></Grid>
          <Grid item xs={6}><TextField {...bind('referralNumber', { required: true, validate: (val: any) => { if (((val || '').split('-')[0] || '').toLowerCase() !== 'seoc' || !(val || '').split('-')[1]) return 'Invalid referral number, please enter a valid SEOC referral'; } })} label="Referral Number" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('nbServicesTotal', { required: true, validate: (val: any) => { if (Number(val) <= 0) return 'Cannot be lower than 0'; } })} type="number" label="# of Total Authorized Services" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('issueDate', { required: true, validate: (val: any) => { if (val && moment().diff(moment(val, 'YYYY-MM-DD')) < 0) return 'Cannot be greater than today'; } })} label="Issue Date" type="date" {...fieldProps} /></Grid>
          <Grid item xs={6}><TextField {...bind('expirationDate', { required: true, validate: (val: any, values: any) => { if (val && values.issueDate && moment(val, 'YYYY-MM-DD').diff(moment(values.issueDate, 'YYYY-MM-DD')) < 0) return 'Needs to be greater than the issue date'; } })} label="Expiration Date" type="date" {...fieldProps} /></Grid>
          <Grid item xs={6}>
            <TextField {...bind('serviceLength', { required: true })} select label="Service Length (in minutes)" {...fieldProps}>
              {/* <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={45}>45 minutes</MenuItem> */}
              <MenuItem value={60}>60 minutes</MenuItem>
              {/* <MenuItem value={75}>75 minutes</MenuItem>
              <MenuItem value={90}>90 minutes</MenuItem>
              <MenuItem value={105}>105 minutes</MenuItem>
              <MenuItem value={120}>120 minutes</MenuItem> */}
            </TextField>
          </Grid>
        </Grid>

        <Grid className={styles.facilityFields} container spacing={3}>
          <Grid item xs={12}><FieldTitle icon="location">Referring VA Facility</FieldTitle></Grid>
          <Grid item xs={6}>
            <TextField {...bind('facilityId', { required: true })} select label="VA Facility" {...fieldProps}>
              {(facilities || []).map(facility => {
                const { facility_id, name } = facility;
                return (
                  <MenuItem key={facility_id} value={facility_id}>{name}</MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField {...bind('insurer', { required: true })} select label="Insurance Identifier" {...fieldProps}>
              <MenuItem value="Optum">Optum</MenuItem>
              {/* <MenuItem value="Triwest">Triwest</MenuItem> */}
            </TextField>
          </Grid>
        </Grid>

        <Grid className={styles.documentationFields} container spacing={3}>
          <Grid item xs={12}><FieldTitle icon="orientation-card">Medical Documentation</FieldTitle></Grid>
          <Grid item xs={12}><TextField {...bind('documentation', { required: false })} multiline rows={5} {...fieldProps} /></Grid>
        </Grid>
      </div>

    </Modal>
  );
}
