import React from 'react';
import cn from 'classnames';
import { NavLink } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import { useAuth } from 'utils/hooks';
import { Alert } from '@material-ui/lab';
import { Header, Menu } from 'components';
import styles from './style.module.scss';

export type LayoutPropType = {
  title?: string,
  titleContent?: React.ReactNode,
  alert?: string,
  alertSeverity?: "error" | "success" | "info" | "warning" | undefined,
  children?: React.ReactNode,
  sideContent?: React.ReactNode,
  className?: string,
  margin?: boolean,
  external?: boolean, // will not render any nav elements (top nav and sidemenu)
  tabs?: Array<Tab>,
  loading?: boolean,
}

type Tab = {
  label: string,
  route: string,
  role?: any, // roles required to see the tab (separate from role prop on route, so will not prevent the route from rendering, it will only hide the tab)
  hidden?: boolean,
}

export default function Layout({ title, titleContent, alert, alertSeverity = "error", children, sideContent, className, margin = false, external, tabs, loading }: LayoutPropType) {
  const { user } = useAuth();
  return (
    <>
      {!external && <Header />}
      <div className={styles.base}>
        {!external && <Menu />}
        <div className={styles.body}>
          {tabs && (
            <div className={styles.tabs}>
              <ul>
                {tabs.map(tab => {
                  const { label, route, role, hidden } = tab;
                  const rolesList = role ? (Array.isArray(role) ? role : [role]) : [];
                  let visible = rolesList.length === 0 ? true : false;
                  rolesList.forEach(role => {
                    if ((user?.roles || []).includes(role)) visible = true;
                  });
                  if (!visible || hidden) return null; 
                  return (
                    <NavLink key={label} to={route} activeClassName={styles.activeTab}>
                      <li>{label}</li>
                    </NavLink>
                  )
                })}
              </ul>
            </div>
          )}
          <div className={cn(styles.content, { [styles['content--hasMargins']]: margin }, className)}>
            <div className={styles['content-main']}>
              {alert && <Alert className={styles.alert} severity={alertSeverity}>{alert}</Alert>}
              {(title || titleContent) && (
                <div className={styles.headerContainer}>
                {title && <div><h1 className={styles.title}>{title}</h1></div>}
                {titleContent && <div>{titleContent}</div>}
                </div>
              )}
              
              <div className={cn(styles.backdrop, { [styles['backdrop--hidden']]: !loading })}>
                <CircularProgress className={styles.spinner} color="inherit" />
              </div>
              {!loading && children && (
                <div className={styles['content-main-children']}>
                  {children}
                </div>
              )}
            </div>
            {sideContent && (
              <div className={styles['content-side']}>
                {sideContent}
              </div>
            )}
          </div>
        </div>
      </div>
      
    </>
  )
}