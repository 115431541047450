import React, { useEffect } from 'react';
import { useState, useDispatch } from 'utils/hooks';
import { MedicalApi } from 'apis';
import moment from 'moment';
import { ServiceRequestType } from 'apis/medical';
import { UIStore } from 'store';
import { SectionLayout, Table } from 'components';
import { Button } from "@material-ui/core";
import ReferralSidePanel from 'sidePanels/Referral';
import styles from './style.module.scss';

export default function Referrals() {
  const [ open, setOpen ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ /*error*/, setError ] = useState<string>();
  const [ serviceRequests, setServiceRequests ] = useState<any>([]);
  const [ activeRow, setActiveRow ] = useState<any>(null);
  const dispatch = useDispatch();
  // const referrals = useSelector(MedicalStore.getReferrals);
  // const serviceRequests = useSelector(MedicalStore.getServiceRequests);
  
  const rowClicked = (row: any) => {
    if (open && activeRow && row.id === activeRow?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRow(row);
    }
  }

  // fetch data on mnount
  useEffect(() => {
    const fetchData = async (searchTerm = null) => {
      setLoading(true);
      try {
        const serviceRequests = await MedicalApi.fetchServiceRequests({});
        setServiceRequests(serviceRequests);
      } catch (error) {
        setError('An error occured while fetching the referrals');
        console.error(error);
      }
      setLoading(false);
    }  
    fetchData();
  }, [dispatch]);

  const openAddReferralModal = () => {
    dispatch(UIStore.openModal({ id: 'add-referral' }));
  }

  // Pick which side panel to show depending on the active row type (referral or service-request)
  const sideContent = (
    <ReferralSidePanel
      open={open}
      onClose={() => setOpen(false)}
      id={activeRow?.id}
      type={activeRow?.type}
    />
  )

  const columns = [
    { id: 'name', label: 'Patient Name', width: 200,  },
    { id: 'refNumber', label: 'Referral #', width: 150 },
    { id: 'date', label: 'Date/Time', width: 150, type: 'moment' },
    { id: 'sessions', label: 'Remaining Sessions', width: 200 },
  ];

  // All pending referral rows waiting to be ingested (allow edit for 5 minutes)
  // const referralRows = referrals.map((referral: ReferralType) => {
  //   const { referral_id, referral_number, patient: { given_name, family_name }, issue_date } = referral;
  //   return {
  //     id: referral_id,
  //     refNumber: referral_number,
  //     name: `${given_name.join(' ')} ${family_name}`,
  //     date: moment(issue_date),
  //     sessions: 1,
  //     type: 'referral',
  //   }
  // })
  // All service requests that were successfully ingested
  const serviceRequestRows = serviceRequests.map((serviceRequest: ServiceRequestType) => {
    const { id, referral_number, /*patient_id,*/ issue_date } = serviceRequest;
    return {
      id,
      refNumber: referral_number,
      name: null,
      date: moment(issue_date),
      sessions: 1,
      type: 'service-request'
    }
  })

  return (
    <SectionLayout title="Medical Referrals" /*alert={error}*/ sideContent={sideContent} titleContent={<Button variant="outlined" color="primary" size="large" onClick={openAddReferralModal}>Add New Referral</Button>}>
      <div className={styles.base}>
        {/* <Table
          loading={loading}
          activeRowId={open && activeRow?.id}
          className={styles.referralsTable}
          name={`${referralRows.length} Tentative Referral${referralRows.length > 1 ? 's' : ''}`}
          allowSearch={false}
          columns={columns}
          rows={referralRows}
          onRowClick={rowClicked}
          autoHeight
          footer={false}
        /> */}
        <Table
          loading={loading}
          activeRowId={open && activeRow?.id}
          className={styles.serviceRequestsTable}
          name={`${serviceRequestRows.length} Referral${serviceRequestRows.length > 1 ? 's' : ''}`}
          localSearch // will run a local search instead of using debounced search callback for backend search api call
          columns={columns}
          rows={serviceRequestRows}
          onRowClick={rowClicked}
          autoHeight
        />
      </div>
    </SectionLayout>
  )
}