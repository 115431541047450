import React from 'react';
import { UIStore } from 'store';
import { helper } from 'utils';
import { useSelector, useDispatch } from 'utils/hooks';
import modalMapper from './map';

export { default as CopyToHsrm } from './CopyToHsrm';
export { default as AddReferral } from './AddReferral';

export default function ModalManager() {
  const dispatch = useDispatch();
  const modals = useSelector(UIStore.getModals);
  const closeModal = (id: string, result?: any) => {
    dispatch(UIStore.closeModal({ id, result }));
  }

  return (
    <div>
      {modals.map((modal: { id: string, component?: any, props?: any, closing?: boolean }) => {
        const { id, component, props, closing } = modal;
        const C = component || (modalMapper as any)[id];
        if (!C) return null;

        const modalProps = {
          id,
          onClose: (result: any) => closeModal(id, result),
          closing, 
          scrollTop: () => helper.scrollTop(id),
        }
        return <C key={id} {...props} {...modalProps} modalProps={modalProps} />
      })}
    </div>
  );
}