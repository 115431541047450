import React, { useEffect } from 'react';
import moment from 'moment';
import { MedicalApi } from 'apis';
import { AppointmentType } from 'apis/medical';
import { useState, useDispatch } from 'utils/hooks';
import { UIStore } from 'store';
import Modal, { ModalPropType } from '../templates/Modal';
import { Grid, InputAdornment } from "@material-ui/core";
import FileCopy from '@material-ui/icons/FileCopy';
import { TextField } from 'components';
import styles from './style.module.scss';

export type CopyToHsrmProps = ModalPropType & {
  appointment: AppointmentType,
}

export default function CopyToHsrm({ appointment, onClose, scrollTop, modalProps }: CopyToHsrmProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [serviceRequest, setServiceRequest] = useState<any>();
  // const [notes, setNotes] = useState<string>();

  useEffect(() => {
    async function fetchData() {
      if (appointment?.service_request_id) {
        try {
          const sr = await MedicalApi.fetchServiceRequest(appointment.service_request_id);
          setServiceRequest(sr);
        } catch (error) {
          setError('An error occured while fetching the referral #')
        }
      }
      // try {
      //   const notes = await MedicalApi.fetchAppointmentNotes(appointment.id);
      //   setNotes(notes);
      // } catch (error) {
      //   setError('An error occured while fetching the notes')
      // }
      setLoading(false);
    }
    fetchData();
  }, [appointment])
  
  const onSubmit = async () => {
    try {
      await MedicalApi.setAppointmentAsCopied(appointment.id);

      dispatch(UIStore.openAlert({ title: `Appointment Marked as Copied to HSRM` }));
      if (onClose) (onClose as Function)(true);
    } catch (error) {
      setError('An error occured while marking the appointment as copied to HSRM');
      scrollTop();
      console.error(error);
    }
  }

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    disabled: true,
    InputLabelProps: {
      shrink: true,
    },
    InputProps: {
      style: { pointerEvents: 'none' },
      endAdornment: (
        <InputAdornment position="end">
          <FileCopy />
        </InputAdornment>
      ),
    }
  }

  const copy = (value: string = '') => {
    navigator.clipboard.writeText(value).then(function() {
      dispatch(UIStore.openAlert({ title: `Successfully copied to clipboard`, duration: 1000 }));
    }, function() {
      dispatch(UIStore.openAlert({ title: `Failed to copy to clipboard`, severity: 'error', duration: 1000 }));
    });
  }
 
  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title="Copy Appointment to HSRM"
      description={<>Follow these steps to move the appointment details to HSRM. Need help? Read the Process Overview.</>}
      className={styles.base}
      footerClassName={styles.footer}
      onSubmit={onSubmit}
      actions={[
        { label: 'Back', action: "close" },
        { label: 'Finish', action: "submit" },
      ]}
    >   
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}><p className={styles.title}>1. Find referral in HSRM portal</p></Grid>
        <Grid item xs={6} className={styles.copyWrapper} onClick={() => copy(serviceRequest?.referral_number)}><TextField value={serviceRequest?.referral_number} label="Referral #" {...fieldProps} /></Grid>
      </Grid>
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}><p className={styles.title}>2. Add Appointment Date and Time in HSRM</p></Grid>
        <Grid item xs={6} className={styles.copyWrapper} onClick={() => copy(moment(appointment.date).format('YYYY-MM-DD'))}><TextField value={moment(appointment.date).format('YYYY-MM-DD')} label="Appointment Date" type="date" {...fieldProps} /></Grid>
        <Grid item xs={6} className={styles.copyWrapper} onClick={() => copy(moment(appointment.date).format('hh:mm A'))}><TextField value={moment(appointment.date).format('hh:mm A')} label="Appointment Time" {...fieldProps} /></Grid>
      </Grid>
      {/* <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}><p className={styles.title}>3. Copy or upload Progress Note into HSRM appointment</p></Grid>
        <Grid item xs={12} className={styles.copyWrapper} onClick={() => copy(notes)}><TextField value={notes} multiline rows={5} {...fieldProps} /></Grid>
      </Grid> */}
      <Grid className={styles.section} container spacing={3}>
        <Grid item xs={12}><p className={styles.title}>3. Mark service as completed in HSRM</p></Grid>
      </Grid>
    </Modal>
  );
}
