import React, { useEffect } from 'react';
import { useState } from 'utils/hooks';
// import moment from 'moment';
import { MedicalApi } from 'apis';
import { PatientType } from 'apis/medical';
// import { UIStore, MedicalStore } from 'store';
import { SectionLayout, Table } from 'components';
// import { Button } from "@material-ui/core";
import PatientSidePanel from 'sidePanels/Patient';
import styles from './style.module.scss';

export default function Patients() {
  const [ open, setOpen ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<any>();
  const [ patients, setPatients ] = useState<any>([]);
  const [ activeRow, setActiveRow ] = useState<any>(null);

  // hooks for data fetching
  const [ filters, setFilters ] = useState<any>({
    page: 1,
    searchTerm: '',
    pageSize: 50,
  })
  // const [ pageSize, setPageSize ] = useState<any>(50);
  // const [ searchTerm, setSearchTerm ] = useState<any>();
  // const [ page, setPage ] = useState<any>(1);
  
  const rowClicked = (row: any) => {
    if (open && activeRow && row.id === activeRow?.id) setOpen(false);
    else {
      setOpen(true);
      setActiveRow(row);
    }
  }

  // fetch data on mnount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const patients = await MedicalApi.fetchPatients({
          search: filters.searchTerm,
          limit: filters.pageSize,
          offset: (filters.page - 1) * filters.pageSize,
        });
        setPatients(patients);
      } catch (error) {
        setError('An error occured while fetching the patients');
        setFilters({
          ...filters,
          page: 1,
          pageSize: 50,
        })
        console.error(error);
      }
      setLoading(false);
    }  
    fetchData();
  }, [filters]);

  const pageSizeChangeHandler = ({ pageSize }: any) => {
    if (filters.pageSize !== pageSize) {
      setFilters({
        ...filters,
        pageSize
      })
    }
  }

  const pageChangeHandler = ({ page }: any) => {
    if (filters.page !== page) {
      setFilters({
        ...filters,
        page
      })
    }
  }

  const searchHandler = (search: any) => {
    if (filters.searchTerm !== search) {
      setFilters({
        ...filters,
        page: 1,
        searchTerm: search
      })
    }
  }

  const sortChangeHandler = ({ sortModel }: any) => {
    // const columnId = sortModel?.[0].field;
    // const sort = sortModel?.[0].sort;
  }

  // Pick which side panel to show depending on the active row type (referral or service-request)
  const sideContent = (
    <PatientSidePanel
      open={open}
      onClose={() => setOpen(false)}
      id={activeRow?.id}
    />
  )

  const columns = [
    { id: 'name', label: 'Patient Name', width: 200,  },
    { id: 'icn', label: 'ICN', width: 150 },
    { id: 'referralNumber', label: 'Referral #', width: 150, },
    { id: 'status', label: 'Status', type: 'status', width: 100 },
  ];

  const patientRows = (patients || []).map((patient: PatientType) => {
    const { id, referral_number, icn, given_names, family_name, active } = patient;
    const fullName = `${given_names.join(' ')} ${family_name}`;

    return {
      id,
      name: fullName,
      icn,
      referralNumber: referral_number,
      status: active,
    }
  })

  return (
    <SectionLayout title="Medical Patients" alert={error} sideContent={sideContent}>
      <div className={styles.base}>
        <Table
          loading={loading}
          activeRowId={open && activeRow?.id}
          name={`${patientRows.length} Patient${patientRows.length > 1 ? 's' : ''}`}
          onSearchChange={searchHandler}
          columns={columns}
          rows={patientRows}
          onRowClick={rowClicked}
          rowsPerPageOptions={[25, 50, 100]}
          page={filters.page}
          pageSize={filters.pageSize}
          onPageSizeChange={pageSizeChangeHandler}
          onPageChange={pageChangeHandler}
          onSortModelChange={sortChangeHandler}
          serverPagination
          serverRowCount={10000}
          autoHeight
        />
      </div>
    </SectionLayout>
  )
}