import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import authReducer, * as Auth from './Auth';
import medicalReducer, * as Medical from './Medical';
import uiReducer, * as UI from './UI';

const store = configureStore({
  reducer: {
    auth: authReducer,
    medical: medicalReducer,
    ui: uiReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
        UI.slice.actions.openModal.type, UI.slice.actions.warnModalForClosure.type, UI.slice.actions.closeModal.type
      ]
    }
  })
});


export const persistor = persistStore(store);
export default store;

// also exporting all slices actions for easier import
export const AuthStore = Auth;
export const MedicalStore = Medical;
export const UIStore = UI;