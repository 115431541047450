import React, { useEffect } from 'react';
import moment from 'moment';
import { MemberApi } from 'apis';
import { PatientType } from 'apis/medical';
import { useState, useForm } from 'utils/hooks';
// import { UIStore } from 'store';
import { Grid, MenuItem } from "@material-ui/core";
import { TextField,/* AutocompleteField,*/ PhoneField/*, Checkbox*/ } from 'components';
import Form, { FormProps } from 'components/Form';
// import AddressField, { AddressSuggestionType } from 'components/fields/AddressField';
// import styles from './style.module.scss';

export type PatientFormProps = FormProps & {
  patient?: PatientType,
}

export default function PatientForm({ patient, edit, ...rest }: PatientFormProps) {
  const { bind, /*bindCustom,*/ form } = useForm();
  const [ memberSearch/*, setMemberSearch*/ ] = useState();
  const [ /*members*/, setMembers ] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const members = await MemberApi.searchMembers({ name: memberSearch });
        setMembers(members);
      } catch (error) {
        console.error(error);
      }
    }
    if (memberSearch) fetchMembers();
  }, [memberSearch]);

  useEffect(() => {
    form.setFields({
      icn: patient?.icn,
      edipi: patient?.edipi,
      firstName: (patient?.given_names || []).join(' '),
      familyName: patient?.family_name,
      dateOfBirth: patient?.birth_date ? moment(patient.birth_date).format('YYYY-MM-DD') : '',
      ssnLast4: patient?.ssn,
      phone: patient?.phone,
      mobile: '',
      address1: patient?.address?.[0]?.line?.[0],
      unit: patient?.address?.[0]?.line?.[1],
      city: patient?.address?.[0]?.city,
      state: patient?.address?.[0]?.state,
      zip: patient?.address?.[0]?.postal_code,
      email: patient?.email,
      gender: patient?.gender,
      vetStatus: '',
      memberId: patient?.member_id,
    })
  }, [patient]) // eslint-disable-line react-hooks/exhaustive-deps

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputProps: {
      readOnly: !edit,
    },
    InputLabelProps: {
      shrink: true,
    }
  }

  // const options = (members || []).map((member) => {
  //   return {
  //     id: '123',
  //     name: 'John Doe',
  //   }
  // });
 
  return (
    <Form title="Patient Information" icon="orientation-card" form={form} {...rest}>
      <Grid item xs={6}><TextField {...bind('icn', { required: edit })} label="Integration Control Number (ICN)" autoFocus {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('edipi', { required: edit })} label="EDIPI number" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('firstName', { required: edit })} label="First Name" {...fieldProps} /></Grid>
      {/* <Grid item xs={6}><TextField {...bind('middleName')} label="Middle Name (Optional)" {...fieldProps} /></Grid> */}
      <Grid item xs={6}><TextField {...bind('familyName', { required: edit })} label="Last Name" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('dateOfBirth', { required: edit })} label="Date of Birth" type="date" {...fieldProps} /></Grid>
      {!edit && <Grid item xs={6}><TextField {...bind('ssnLast4')} label="Last 4 Digits of SSN" {...fieldProps} /></Grid>}
      {edit && <Grid item xs={6}><TextField {...bind('ssn', { required: true })} inputProps={{ maxLength: 9 }} label="SSN" {...fieldProps} /></Grid>}
      <Grid item xs={6}><PhoneField {...bind('phone', { required: edit })} label="Phone Number" {...fieldProps} /></Grid>
      <Grid item xs={6}><PhoneField {...bind('mobile')} label="Mobile Phone Number (Optional)" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('address1', { required: edit })} label="Address" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('unit')} label="Unit Number (Optional)" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('city', { required: edit })} label="City" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('state', { required: edit })} inputProps={{ maxLength: 2 }} label="State" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('zip', { required: edit })} inputProps={{ maxLength: 5 }} label="Zip" {...fieldProps} /></Grid>
      <Grid item xs={6}><TextField {...bind('email', { required: edit })} label="Email (Optional)" {...fieldProps} /></Grid>
      <Grid item xs={6}>
        <TextField {...bind('gender')} select label="Patient Gender (Optional)" {...fieldProps}>
          <MenuItem value="">-</MenuItem>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}><TextField {...bind('memberId')} label="Zeel Member Account ID" {...fieldProps} /></Grid>
      {/* <Grid item xs={12}>
        <AutocompleteField
          {...bindCustom('member', { required: edit })}
          filterOptions={(x: any) => x}
          getOptionLabel={(o: any) => `${o?.name} (${o?.id})`}
          onInputChange={(value: any) => setMemberSearch(value)}
          options={options}
          label="Link to existing Zeel Member account"
          {...fieldProps}
        />
      </Grid> */}
    </Form>
  );
}
