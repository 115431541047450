import React, { useEffect } from 'react';
import { useState, useDispatch } from 'utils/hooks';
import moment from 'moment';
import { MedicalApi } from 'apis';
import { AppointmentType } from 'apis/medical';
import { UIStore } from 'store';
import { SectionLayout, Table, Button } from 'components';
import styles from './style.module.scss';

export default function AppointmentsUpdate() {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string>();
  const [ page, setPage ] = useState<any>(1);
  const [ search, setSearch ] = useState<any>();
  const [ totalRows, setTotalRows ] = useState<any>(0);
  const [ appointments, setAppointments ] = useState<Array<AppointmentType>>([]);
  const dispatch = useDispatch();
  
  // fetch data on mount
  const fetchData = async () => {
    setLoading(true);
    try {
      const { appointments, total } = await MedicalApi.fetchAppointmentsToCopy({ page, search });
      setAppointments(appointments);
      setTotalRows(total);
    } catch (error) {
      setError('An error occured while fetching the appointments');
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [page, search]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPageChange = ({ page }: any) => {
    setPage(page);
  }

  const onSearchChange = (search: any) => {
    setPage(1); // reset page number
    setSearch(search);
  }

  const onSortChange = (e: any) => {
    console.log('sort changed', e);
  }

  const openCopyModal = (appointmentId: string) => {
    const appointment = (appointments || []).find(appt => appt.id === appointmentId);
    if (appointment) {
      dispatch(UIStore.openModal({ id: 'copy-to-hsrm', props: { appointment }, callback: (response: any) => {
        if (response) {
          fetchData();
        }
      } }));
    }
  }

  const columns = [
    { id: 'name', label: 'Patient Name', width: 300, sortable: false },
    { id: 'date', label: 'Date/Time', width: 150, type: 'moment', sortable: false },
    { id: 'stage', label: 'Stage', width: 150, sortable: false },
    { id: 'status', label: 'Status', width: 250, sortable: false },
    { id: 'action', label: ' ', width: 200, sortable: false, renderCell: ({ data }: any) => {
      if (data.action === 'copy') {
        return (
          <Button state="secondary" onClick={() => openCopyModal(data.id)}>
            Copy to HSRM
          </Button>
        )
      }
      return null;
    } },
  ];

  const appointmentRows = (appointments || []).map((appointment: AppointmentType) => {
    const { id, fname, lname, date, stage, copied_into_hsrm } = appointment;
    return {
      id,
      name: `${fname} ${lname}`,
      date: moment(date),
      stage,
      status: copied_into_hsrm ? 'Copied' : 'Update HSRM',
      action: copied_into_hsrm ? null : 'copy',
    }
  })

  return (
    <SectionLayout alert={error}>
      <div className={styles.base}>
        <Table
          loading={loading}
          className={styles.serviceRequestsTable}
          name={`${(appointments || []).length} Appointments Need Attention`}
          columns={columns}
          rows={appointmentRows}
          onSearchChange={onSearchChange}
          autoHeight
          pageSize={50}
          serverPagination={true}
          serverRowCount={totalRows}
          onPageChange={onPageChange}
          rowsPerPageOptions={[50]}
          onSortModelChange={onSortChange}
        />
      </div>
    </SectionLayout>
  )
}