import React, { useEffect, useState } from 'react';
import { useDispatch, useIdleTimer } from 'utils/hooks';
import { AuthStore } from 'store';
import Icon from '../Icon';
import styles from './style.module.scss';

export default function Idler() {
  const dispatch = useDispatch();
  const [timeRemaining, setTimeRemaining] = useState<number | null>();

  const handleOnIdle = (event: Event) => {
    dispatch(AuthStore.logout());
  }

  const { getRemainingTime } = useIdleTimer({
    timeout: 600000, // will be kicked out after 10 minutes
    onIdle: handleOnIdle,
    debounce: 500
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(getRemainingTime());
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [getRemainingTime]);

  if (!timeRemaining || timeRemaining > 60000) return null;
  return (
    <div className={styles.base}>
      <Icon name="exclamation" size={40} className={styles.icon} />
      Idle kickout in {Math.round(timeRemaining / 1000)} seconds
    </div>
  );
}
