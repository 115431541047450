import { ReactComponent as AddCircle } from './svgs/add-circle.svg';
import { ReactComponent as AddUser } from './svgs/add-user.svg';
import { ReactComponent as Add } from './svgs/add.svg';
import { ReactComponent as ArrowDown } from './svgs/arrow-down.svg';
import { ReactComponent as ArrowLeft } from './svgs/arrow-left.svg';
import { ReactComponent as ArrowLink } from './svgs/arrow-link.svg';
import { ReactComponent as ArrowRight } from './svgs/arrow-right.svg';
import { ReactComponent as ArrowUp } from './svgs/arrow-up.svg';
import { ReactComponent as AvatarFemale } from './svgs/avatar-female.svg';
import { ReactComponent as AvatarMale } from './svgs/avatar-male.svg';
import { ReactComponent as AvatarNeutral } from './svgs/avatar-neutral.svg';
import { ReactComponent as Bell } from './svgs/bell.svg';
import { ReactComponent as Briefcase } from './svgs/briefcase.svg';
import { ReactComponent as BulletList } from './svgs/bullet-list.svg';
import { ReactComponent as CalendarAddCircle } from './svgs/calendar-add-circle.svg';
import { ReactComponent as CalendarCircle } from './svgs/calendar-circle.svg';
import { ReactComponent as CalendarEmpty } from './svgs/calendar-empty.svg';
import { ReactComponent as Calendar } from './svgs/calendar.svg';
import { ReactComponent as Camera } from './svgs/camera.svg';
import { ReactComponent as CandleLeaf } from './svgs/candle-leaf.svg';
import { ReactComponent as CaretDown } from './svgs/caret-down.svg';
import { ReactComponent as CaretLeft } from './svgs/caret-left.svg';
import { ReactComponent as CaretRight } from './svgs/caret-right.svg';
import { ReactComponent as CaretUp } from './svgs/caret-up.svg';
import { ReactComponent as Cart } from './svgs/cart.svg';
import { ReactComponent as ChatBubble } from './svgs/chat-bubble.svg';
import { ReactComponent as ChatSms } from './svgs/chat-sms.svg';
import { ReactComponent as Chat } from './svgs/chat.svg';
import { ReactComponent as Checkin } from './svgs/checkin.svg';
import { ReactComponent as Checklist } from './svgs/checklist.svg';
import { ReactComponent as CheckmarkCircle } from './svgs/checkmark-circle.svg';
import { ReactComponent as Checkmark } from './svgs/checkmark.svg';
import { ReactComponent as Checkout } from './svgs/checkout.svg';
import { ReactComponent as ClockCircle } from './svgs/clock-circle.svg';
import { ReactComponent as Clock } from './svgs/clock.svg';
import { ReactComponent as Coffee } from './svgs/coffee.svg';
import { ReactComponent as CreditCard } from './svgs/credit-card.svg';
import { ReactComponent as CreditCardCrossed } from './svgs/credit-card-crossed.svg';
import { ReactComponent as DollarArrowCircle } from './svgs/dollar-arrow-circle.svg';
import { ReactComponent as DollarArrow } from './svgs/dollar-arrow.svg';
import { ReactComponent as DollarCrossed } from './svgs/dollar-crossed.svg';
import { ReactComponent as Dollar } from './svgs/dollar.svg';
import { ReactComponent as DotMenuHorizontal } from './svgs/dot-menu-horizontal.svg';
import { ReactComponent as DotMenuVertical } from './svgs/dot-menu-vertical.svg';
import { ReactComponent as DoubleRefresh } from './svgs/double-refresh.svg';
import { ReactComponent as Download } from './svgs/download.svg';
import { ReactComponent as Duplicate } from './svgs/duplicate.svg';
import { ReactComponent as EditPencilCircle } from './svgs/edit-pencil-circle.svg';
import { ReactComponent as EditPencil } from './svgs/edit-pencil.svg';
import { ReactComponent as Elevator } from './svgs/elevator.svg';
import { ReactComponent as Email } from './svgs/email.svg';
import { ReactComponent as Exclamation } from './svgs/exclamation.svg';
import { ReactComponent as EyeHide } from './svgs/eye-hide.svg';
import { ReactComponent as EyeSimpleHide } from './svgs/eye-simple-hide.svg';
import { ReactComponent as EyeSimple } from './svgs/eye-simple.svg';
import { ReactComponent as Eye } from './svgs/eye.svg';
import { ReactComponent as Flag } from './svgs/flag.svg';
import { ReactComponent as GearToolCircle } from './svgs/gear-tool-circle.svg';
import { ReactComponent as Gear } from './svgs/gear.svg';
import { ReactComponent as GenderFemale } from './svgs/gender-female.svg';
import { ReactComponent as GenderMale } from './svgs/gender-male.svg';
import { ReactComponent as GiftCircle } from './svgs/gift-circle.svg';
import { ReactComponent as Gift } from './svgs/gift.svg';
import { ReactComponent as GraduateHat } from './svgs/graduate-hat.svg';
import { ReactComponent as HamburgerMenu } from './svgs/hamburger-menu.svg';
import { ReactComponent as Hand } from './svgs/hand.svg';
import { ReactComponent as Hanger } from './svgs/hanger.svg';
import { ReactComponent as Heart } from './svgs/heart.svg';
import { ReactComponent as HotelBell } from './svgs/hotel-bell.svg';
import { ReactComponent as Hourglass } from './svgs/hourglass.svg';
import { ReactComponent as House } from './svgs/house.svg';
import { ReactComponent as HouseAlt } from './svgs/house-alt.svg';
import { ReactComponent as Information } from './svgs/information.svg';
import { ReactComponent as Kustomer } from './svgs/kustomer.svg';
import { ReactComponent as LeafArrow } from './svgs/leaf-arrow.svg';
import { ReactComponent as LightningAlt } from './svgs/lightning-alt.svg';
import { ReactComponent as Lightning } from './svgs/lightning.svg';
import { ReactComponent as Linens } from './svgs/linens.svg';
import { ReactComponent as Location } from './svgs/location.svg';
import { ReactComponent as Lock } from './svgs/lock.svg';
import { ReactComponent as MassageChair } from './svgs/massage-chair.svg';
import { ReactComponent as MassageTableCircle } from './svgs/massage-table-circle.svg';
import { ReactComponent as MassageTable } from './svgs/massage-table.svg';
import { ReactComponent as MedicalCrossCircle } from './svgs/medical-cross-circle.svg';
import { ReactComponent as MegaphoneCircle } from './svgs/megaphone-circle.svg';
import { ReactComponent as Minus } from './svgs/minus.svg';
import { ReactComponent as Moon } from './svgs/moon.svg';
import { ReactComponent as Muscles } from './svgs/muscles.svg';
import { ReactComponent as Navigate } from './svgs/navigate.svg';
import { ReactComponent as NewWindow } from './svgs/new-window.svg';
import { ReactComponent as NoParking } from './svgs/no-parking.svg';
import { ReactComponent as NoSmoking } from './svgs/no-smoking.svg';
import { ReactComponent as NotesChecked } from './svgs/notes-checked.svg';
import { ReactComponent as Notes } from './svgs/notes.svg';
import { ReactComponent as OrientationCard } from './svgs/orientation-card.svg';
import { ReactComponent as Parking } from './svgs/parking.svg';
import { ReactComponent as Paw } from './svgs/paw.svg';
import { ReactComponent as Phone } from './svgs/phone.svg';
import { ReactComponent as Photo } from './svgs/photo.svg';
import { ReactComponent as Preferences } from './svgs/preferences.svg';
import { ReactComponent as PriceTagCircle } from './svgs/price-tag-circle.svg';
import { ReactComponent as PriceTag } from './svgs/price-tag.svg';
import { ReactComponent as Printer } from './svgs/printer.svg';
import { ReactComponent as PromoCircle } from './svgs/promo-circle.svg';
import { ReactComponent as Question } from './svgs/question.svg';
import { ReactComponent as Refresh } from './svgs/refresh.svg';
import { ReactComponent as PadWithStar } from './svgs/pad-with-star.svg';
import { ReactComponent as Search } from './svgs/search.svg';
import { ReactComponent as Share } from './svgs/share.svg';
import { ReactComponent as SocialFacebook } from './svgs/social-facebook.svg';
import { ReactComponent as SocialInstagram } from './svgs/social-instagram.svg';
import { ReactComponent as SocialTwitter } from './svgs/social-twitter.svg';
import { ReactComponent as SocialYelp } from './svgs/social-yelp.svg';
import { ReactComponent as Sort } from './svgs/sort.svg';
import { ReactComponent as SpaFlower } from './svgs/spa-flower.svg';
import { ReactComponent as SpiralHorizontal } from './svgs/spiral-horizontal.svg';
import { ReactComponent as Stairs } from './svgs/stairs.svg';
import { ReactComponent as StarFilled } from './svgs/star-filled.svg';
import { ReactComponent as Star } from './svgs/star.svg';
import { ReactComponent as Stopwatch } from './svgs/stopwatch.svg';
import { ReactComponent as Trash } from './svgs/trash.svg';
import { ReactComponent as Unavailable } from './svgs/unavailable.svg';
import { ReactComponent as UserVerifiedCircle } from './svgs/user-verified-circle.svg';
import { ReactComponent as User } from './svgs/user.svg';
import { ReactComponent as VipConciergeCircle } from './svgs/vip-concierge-circle.svg';
import { ReactComponent as XCircleFilled } from './svgs/x-circle-filled.svg';
import { ReactComponent as XCircle } from './svgs/x-circle.svg';
import { ReactComponent as X } from './svgs/x.svg';

export default {
  "add-circle": AddCircle,
  "add-user": AddUser,
  "add": Add,
  "arrow-down": ArrowDown,
  "arrow-left": ArrowLeft,
  "arrow-link": ArrowLink,
  "arrow-right": ArrowRight,
  "arrow-up": ArrowUp,
  "avatar-female": AvatarFemale,
  "avatar-male": AvatarMale,
  "avatar-neutral": AvatarNeutral,
  "bell": Bell,
  "briefcase": Briefcase,
  "bullet-list": BulletList,
  "calendar-add-circle": CalendarAddCircle,
  "calendar-circle": CalendarCircle,
  "calendar-empty": CalendarEmpty,
  "calendar": Calendar,
  "camera": Camera,
  "candle-leaf": CandleLeaf,
  "caret-down": CaretDown,
  "caret-left": CaretLeft,
  "caret-right": CaretRight,
  "caret-up": CaretUp,
  "cart": Cart,
  "chat-bubble": ChatBubble,
  "chat-sms": ChatSms,
  "chat": Chat,
  "checkin": Checkin,
  "checklist": Checklist,
  "checkmark-circle": CheckmarkCircle,
  "checkmark": Checkmark,
  "checkout": Checkout,
  "clock-circle": ClockCircle,
  "clock": Clock,
  "coffee": Coffee,
  "credit-card": CreditCard,
  "credit-card-crossed": CreditCardCrossed,
  "dollar-arrow-circle": DollarArrowCircle,
  "dollar-arrow": DollarArrow,
  "dollar-crossed": DollarCrossed,
  "dollar": Dollar,
  "dot-menu-horizontal": DotMenuHorizontal,
  "dot-menu-vertical": DotMenuVertical,
  "double-refresh": DoubleRefresh,
  "download": Download,
  "duplicate": Duplicate,
  "edit-pencil-circle": EditPencilCircle,
  "edit-pencil": EditPencil,
  "elevator": Elevator,
  "email": Email,
  "exclamation": Exclamation,
  "eye-hide": EyeHide,
  "eye-simple-hide": EyeSimpleHide,
  "eye-simple": EyeSimple,
  "eye": Eye,
  "flag": Flag,
  "gear-tool-circle": GearToolCircle,
  "gear": Gear,
  "gender-female": GenderFemale,
  "gender-male": GenderMale,
  "gift-circle": GiftCircle,
  "gift": Gift,
  "graduate-hat": GraduateHat,
  "hamburger-menu": HamburgerMenu,
  "hand": Hand,
  "hanger": Hanger,
  "heart": Heart,
  "hotel-bell": HotelBell,
  "hourglass": Hourglass,
  "house": House,
  "house-alt": HouseAlt,
  "information": Information,
  "kustomer": Kustomer,
  "leaf-arrow": LeafArrow,
  "lightning-alt": LightningAlt,
  "lightning": Lightning,
  "linens": Linens,
  "location": Location,
  "lock": Lock,
  "massage-chair": MassageChair,
  "massage-table-circle": MassageTableCircle,
  "massage-table": MassageTable,
  "medical-cross-circle": MedicalCrossCircle,
  "megaphone-circle": MegaphoneCircle,
  "minus": Minus,
  "moon": Moon,
  "muscles": Muscles,
  "navigate": Navigate,
  "new-window": NewWindow,
  "no-parking": NoParking,
  "no-smoking": NoSmoking,
  "notes-checked": NotesChecked,
  "notes": Notes,
  "orientation-card": OrientationCard,
  "parking": Parking,
  "paw": Paw,
  "phone": Phone,
  "photo": Photo,
  "preferences": Preferences,
  "price-tag-circle": PriceTagCircle,
  "price-tag": PriceTag,
  "printer": Printer,
  "promo-circle": PromoCircle,
  "question": Question,
  "refresh": Refresh,
  "pad-with-star": PadWithStar,
  "search": Search,
  "share": Share,
  "social-facebook": SocialFacebook,
  "social-instagram": SocialInstagram,
  "social-twitter": SocialTwitter,
  "social-yelp": SocialYelp,
  "sort": Sort,
  "spa-flower": SpaFlower,
  "spiral-horizontal": SpiralHorizontal,
  "stairs": Stairs,
  "star-filled": StarFilled,
  "star": Star,
  "stopwatch": Stopwatch,
  "trash": Trash,
  "unavailable": Unavailable,
  "user-verified-circle": UserVerifiedCircle,
  "user": User,
  "vip-concierge-circle": VipConciergeCircle,
  "x-circle-filled": XCircleFilled,
  "x-circle": XCircle,
  "x": X,
};
